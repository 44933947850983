import { Box, Popover, Stack, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

interface ReasonInputProps {
    info: {
        value :string | number | null, 
        reason: string | null, 
        field: string
    },
    children?: React.ReactNode,
    onClose: (field: string, reason: string | null) => void
}
/**
 * @brief Simple wrapper for inputting reasons for changes in workhours fields
 */
export default function ReasonInput({info, children, onClose}: ReasonInputProps) {

    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [reason, setReason] = useState<string | null>(info.reason);

    useEffect(() => {
        setReason(info.reason);
    }, [info.reason]);

    const onChange = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        console.log(target.name, target.value);
        setReason(target.value);
    }

    return (
        <>
            <TextField onClick={(event) => {
                setIsSelected(true);
                setAnchorEl(event.currentTarget);
            }}
            onKeyDown={(event) => {
                if (event.key !== "Tab") {
                    setIsSelected(true);
                    setAnchorEl(event.currentTarget);
                }
            }}
            sx={{cursor: "pointer"}}
            value={info.value}
            />
            <Popover 
                open={isSelected}
                onClose={(e) => {
                    setIsSelected(false);
                    setAnchorEl(null);
                    console.log(reason);
                    onClose(info.field, reason)
                }}
                anchorEl={anchorEl}
            >
                <Stack sx={{m: 1, rowGap: 2}}>
                    {children}
                                            
                    <TextField 
                    label={"Muutoksen syy"} 
                    defaultValue={reason}
                    InputLabelProps={{shrink: true}}
                    name={"reason"}
                    onChange={onChange}
                    multiline
                    minRows={2}
                    error={!reason || reason === null || reason.length === 0}
                    />
                </Stack>
            </Popover>
        </>
    )

}