import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";

// A4 ratio
const A4_ratio = 1 / 1.41;

/**
 * @typedef {{
 *  width: number | 'a4',
 *  children: JSX.Element | JSX.Element[]
 * }} PrintableReportBaseProps
 */

// Create forward ref
const PrintableReportBase = forwardRef(PrintableReportBaseComponent);

/**
 * 
 * @param {PrintableReportBaseProps} props 
 * @returns 
 */
function PrintableReportBaseComponent(props, ref) {


    const children = Array.isArray(props.children) ? props.children : [props.children];

    return <Box
        sx={{
            width: props.width === 'a4' ? '297mm' : `${props.width}`,
            height: props.width === 'a4' ? '210mm' : `${props.width * A4_ratio * children.length}px`,
            backgroundImage: 'url("./bg/emission-report-bg.jpg")',
            backgroundSize: props.width === 'a4' ? '297mm 210mm' : `${props.width}px ${props.width * A4_ratio}px`,
            backgroundRepeat: 'repeat-y',
            p: 0,
            m: 0,
            overflow: 'hidden'
        }}
        ref={ref}
    >
        <Box
            sx={{ m: 0, backdropFilter: 'blur(5px)', width: '100%', height: '100%' }}
        >
            {
                children.map((e, i) => {

                    return <Box
                            key={"prbd-" + i} 
                            sx={{ width: '100%', height: props.width === 'a4' ? '210mm' : `${props.width * A4_ratio}px` }}
                        >
                            {e}
                        </Box>
                })
            }
        </Box>

    </Box>
}

export default PrintableReportBase;