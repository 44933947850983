
/**
 * @typedef {'item'|'news'|'job'} FileTableLink
 */

import { FuelType, FuelVendor } from "../components/FuelPriceCard";

/**
 * Default 'set' response
 * @typedef {{status:boolean}} DefaultSetResponse
 */


/**
 * `Item` table
 * @typedef {{  id: number, itemNumber: string, client: number, cargoType: number|null, active: number, name: string, shortName: string|null, notes: string|null,
 *              loadingAddress: string|null, unloadAddress: string|null, loadingCoord: string|null, unloadCoord: string|null, billingReason: number, reqHours: number,
 *              reqTons: number, reqM3: number, reqDetails: number, reqCount: number, reqKm: number, reqWaitTime: number, kilometres: number, hours: number, 
 *              warrantyLoad: number, detailDesc: string, defaultType: number, cargoLabel: string}} LogitarItem
 */


/**
 * `Client` table
 * @typedef {{  id: number, VATNumber: string|null, fax: string|null, name: string, phoneNumber: string|null, email: string|null, 
 *              destination: string, currency: string, businessID: string}} LogitarClient
 */

/**
 * GetFileResponse
 * @typedef {{file:{id:number,item:number|null,news: number|null,job:number|null,name:string,MIME:string,data:string,size:number},status:true}} GetFileResponse
 */

/**
 * SetFileRequestFile
 * @typedef {{item?:number|null,news?:number|null,job?:number|null,name:string,MIME:string,data:string,size:number}} SetFileRequest
 */


/**
 * GetItemsResponseAll
 * @typedef {LogitarItem & {clientName: string, cargoName: string}} GetItemsResponseAll
 */

/**
 * GetItemsResponseList
 * @typedef {{  id: number, itemNumber: string, client: number, shortName: 
 *              string, name: string, cargoType: number|null, clientName: string, cargoName: string|null, cargoLabel: string}} GetItemsResponseList
 */

/**
 * GetItemsResponseAllFile
 * @typedef {LogitarItem & {fileID: number, fileName: string, MIME: string, size: number, data: string}} GetItemsResponseAllFile
 */

/**
 * GetInvoiceResponse
 * @typedef {{
 *    invoice: {
 *        vehicles: {
 *            [key: number]: {
 *                id: number, 
 *                licenseNumber: number
 *            }
 *        },
 *        items: {
 *            [key: number]: {
 *                id: number,
 *                itemNumber: string,
 *                shortName: string,
 *                name: string,
 *                billingReason: number,
 *                talenomProduct: string|null
 *            }
 *        },
 *        clients: {
 *            [key: number]: {
 *                id: number,
 *                name: string,
 *                talenomClient: string|null
 *            }
 *        },
 *        jobs: {
 *            id: number,
 *            item: number,
 *            vehicle: number,
 *            user: number,
 *            client: number,
 *            date: string,
 *            billingDate: string,
 *            tons: number,
 *            m3: number,
 *            count: number,
 *            hours: number,
 *            kilometres: number,
 *            waitTime: number,
 *            details: string,
 *            loadTime: string,
 *            unloadTime: string,
 *            cargoTypeLabel: string
 *        }[]
 *    },
 *    status: true
 *}} GetInvoiceResponse
 */

/**
 * CreateTalenomInvoiceResponse
 * @typedef {{
 *     talenomError?: string,
 *     status: boolean
 * }} CreateTalenomInvoiceResponse
 */

/**
 * GetWeekOrdersResponse
 * @typedef {{
 *  days: {
 *      [key: string]: {
 *          [key: number]: {
 *              order: number,
 *              date: string,
 *              endDate: string | null,
 *              days: string,
 *              recurring: number,
 *              replay: number,
 *              itemNumber: string,
 *              details: string,
 *              name: string,
 *              shortName: string,
 *              doneCount: number
 *          }
 *      }
 *  }
 *  status: true
 * }} GetWeekOrdersResponse
 */

/**
 * VehicleLocation
 * @typedef {{
 *  vehicle: number,
 *  licenseNumber: string,
 *  position: {
 *    latitude: number,
 *    longitude: number,
 *    dateTime: string,
 *    speed: number
 *  },
 *  job: any,
 *  alert: import("../components/VehicleAlertNotifier").VehicleAlert | null
 * }} VehicleLocation
 */

/**
 * VehicleLocationPath
 * @typedef {{
 *  vehicle: {
 *   id: number,
 *   licenseNumber: string,
 *   type: number,
 *  },
 *  positions: {
 *    latitude: number,
 *    longitude: number,
 *    dateTime: string,
 *    speed: number
 *  }[],
 *  jobs: any[],
 *  alerts: import("../components/VehicleAlertNotifier").VehicleAlert[],
 *  workhours: {
 *   id: number,
 *   startTime: string,
 *   endTime: string,
 *   startTimeMod: string,
 *   endTimeMod: string,
 *   date: string,
 *   shift: 'A'|'I',
 *   user: number,
 *   userName: string
 *  }[]
 * }} VehicleLocationPath
 */

/**
 * GetVehicleLocationsResponse
 * @typedef {{
  *  vehicles: VehicleLocation[] | VehicleLocationPath[],
  *  status: true
  * }} GetVehicleLocationsResponse
  */

/**
 * @typedef {{
 *      dateTime: string,
 *      shift: 'A'|'I',
 *      fuel: number,
 *      fuelType: number,
 *      kilometres: number,
 *      vehicle: number,
 *      user: number
 * }} FuelRow
 */

/**
 * GetFuelResponse
 * @typedef {{
 *  fuel: {
 *      current: FuelRow | null,
 *      previous: FuelRow | null
 *  },
 *  status: true
 * }} GetFuelResponse
 */

/**
 * TrainingRow
 * @typedef {{
 *  header: string,
 *  description: string,
 *  link: string,
 *  dateTime: string,
 *  validFrom?: string,
 *  validTo?: string
 * }} TrainingRow
 */

/**
 * JobSplit
 * @typedef {{
 * id: number,
 * date: string,
 * shift: 'A'|'I',
 * hoursRatio: number,
 * switchLocation: string | null,
 * continuedBy: number | null,
 * vehicle: number | null
 * }} JobSplit
 */

/**
 * DriverReportRow
 * @typedef {{
 *  driver: {
 *    id: number,
 *    name: string,
 *    workhours: {
*      startTime: string | null,
*      endTime: string | null
*     }[],
 *  },
 *  scores: any[],
 *  jobs: {
 *    id: number,
 *    date: string,
 *    tons: number | null,
 *    gpsLoadStart: string,
 *    gpsLoadEnd: string,
 *    gpsUnloadStart: string,
 *    gpsUnloadEnd: string,
 *    estDuration: number,
 *    item: {
 *      id: number,
 *      name: string,
 *      hours: number
 *    },
 *    vehicle: {
 *      id: number,
 *      licenseNumber: string
 *    }
 *  }[]
 * }} DriverReportRow
 */

/**
 * DriverReportsResponse
 * @typedef {{
 *    status: boolean,
 *    drivers: DriverReportRow[]
 * }} DriverReportsResponse
 */

/**
 * @typedef {{
  *     item: import("../misc/LogitarTypes").PlanningItem,
  *     id: number,
  *     user: number | null,
  *     slot: number,
  *     state: number,
  *     checkState: number,
  *     date: string,
  *     billingDate: string | null,
  *     shift: string,
  *     loadTime: string | null,
  *     unloadTime: string | null,
  *     tons: number | null,
  *     m3: number | null,
  *     count: number | null,
  *     hours: number | null,
  *     kilometres: number | null,
  *     MWh: number | null,
  *     waitTime: number | null,
  *     type: number | null,
  *     planDetails: string | null,
  *     jobDetails: string | null,
  *     talenomBilled: number,
  *     jobSplit: JobSplit | null,
  *     gpsLoadStart: string | null,
  *     gpsLoadEnd: string | null,
  *     gpsUnloadStart: string | null,
  *     gpsUnloadEnd: string | null,
  *     estStart: string | null,
  *     estDuration: number | null,
  *     draft: any | null
 * }} PlanningJob
 */

/**
 * GetPlanningRow
 * @typedef {{
 *   vehicle: import("../misc/LogitarTypes").PlanningVehicle,
 *   jobs: PlanningJob[]
  * }} GetPlanningRow
 */

/**
 * GetPlanningRowIC
 * @typedef {{
 *   client: {
 *    name: string,
 *    displayName: string
 *   },
 *   jobs: PlanningJob[]
 * }} GetPlanningRowIC
*/

/**
 * GetPlanningResponse
 * @typedef {{
 *  vehicles: GetPlanningRow[],
 *  ics: GetPlanningRowIC[],
 *  unassigned: any[],
 *  status: boolean
 * }} GetPlanningResponse
 */

/**
 * @enum {number}
 */
export const ServiceMonitorType = {
  OIL_CHANGE:       1,
  INTERIM_SERVICE:  2,
  YEARLY_SERVICE:   3
}

/**
 * GetServiceMonitorRow
 * @typedef {{
 *  vehicle: {
 *    id: number,
 *    name: string,
 *    licenseNumber: string,
 *    kilometres: number,
 *    inspectionDate: string,
 *    inspectionByDate: string,
 *    registrationDate: string,
 *    type: number
 *  },
 *  driver: {
 *    id: number,
 *    name: string
 *  } | null,
 *  previous: {
 *    id: number,
 *    done: string,
 *    serviceDoneKm: number
 *  } | null,
 *  id: number,
 *  startDate: string,
 *  type: ServiceMonitorType,
 *  yearInterval: number | null,
 *  firstServiceKm: number | null,
 *  nextServiceKm: number | null,
 *  serviceIntervalKm: number | null,
 *  details: string,
 *  servicedByExt: string | null,
 *  status: number,
 *  orderInfo: string | null,
 *  orderInfo2: string | null
 * }} GetServiceMonitorRow
 */

/**
 * GetServiceMonitorResponse
 * @typedef {{
 *  monitors: GetServiceMonitorRow[],
 *  status: boolean
 * }} GetServiceMonitorResponse
 */

/**
 * SetServiceMonitorFormat
 * @typedef {{
 *  id?: number,
 *  vehicle: number,
 *  type: number,
 *  startDate: string,
 *  driver?: number,
 *  serviceIntervalKm: number,
 *  firstServiceKm: number,
 *  details: string,
 *  servicedByExt: string 
 * }} SetServiceMonitorFormat
 */

/**
 * FuelPriceRow
 * @typedef {{
 *  id: number,
 *  date: string,
 *  fuelType: FuelType,
 *  vendor: FuelVendor,
 *  pricePerLitre: number
 * }} FuelPriceRow
 */

/**
 * GetFuelPriceResponse
 * @typedef {{
 *  fuelPrices: FuelPriceRow[],
 *  status: true
 * }} GetFuelPriceResponse
 */

/**
 * PlanningNote
 * @typedef {{
 *  id: number,
 *  date: string,
 *  shift: 'A'|'I',
 *  content?: string
 * }} LogitarPlanningNote
 */