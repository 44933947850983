import { Map, MapProps, Marker, useMap } from "@vis.gl/react-google-maps";
import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import MapRoutes from "./MapRoutes";
import { VehicleLocation, VehicleLocationPath } from "../../api/LogitarApiTypes";
import { VehicleMarker } from "./VehicleMarker";
import { parseLatLngString } from "../MapSelector";
import { VehiclePosition } from "../../views/MapView";
import { Polyline } from "./Polyline";
import { mapThemes } from "../../config/Themes/MapThemes";

const defaultCenter = { lat: 65.518, lng: 26.785 };
const defaultZoom = 5;

// TODO: use MapSelector's parseLatLngString instead of parsing independently

type MapViewerProps = {
    id?: string;
    initialCenter?: { lat: number, lng: number };
    initialZoom?: number;
    initialBounds?: {lat: number, lng: number}[];
    // TODO: this is supposed to be getplanning type of jobs change this later
    jobs?: any[];
    vehicles?: VehiclePosition[];
    vehiclePath?: {latitude: number, longitude: number}[];
    mapLabel?: React.ReactNode | string;
    mapLabelStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    enableRoutes?: boolean;
    mapProps?: Partial<MapProps>;
};

export default function MapViewer(props: MapViewerProps) {

    const [center, setCenter] = useState(props.initialCenter || defaultCenter);
    const [zoom, setZoom] = useState(props.initialZoom || defaultZoom);
    const [bounds, setBounds] = useState<{ lat: number, lng: number }[]>(props.initialBounds || []);

    const [selectedJob, setSelectedJob] = useState<any>(props.jobs?.[0] || null);
    const map = useMap(props.id || "logitar3-map");

    const theme = useTheme();

    const depCoords = selectedJob?.item.departure.coordinates?.split(',');
    const arrCoords = selectedJob?.item.arrival.coordinates?.split(',');

    useEffect(() => {
        if(map) {
            if(bounds.length > 0) {
                const _bounds = new window.google.maps.LatLngBounds();
                for(const bound of bounds) {
                    _bounds.extend(bound);
                }
                map.fitBounds(_bounds, 12);
            }
        }
    }, [bounds, map])

    return (
        <Map
            center={center}
            zoom={zoom}
            style={{...props.style}}
            onCameraChanged={(ev) => {
                setCenter(ev.detail.center);
                setZoom(ev.detail.zoom);
            }}
            zoomControl={false}
            //styles={theme.palette.mode === 'light' ? mapThemes.light : mapThemes.dark}
            mapId={theme.palette.mode === 'light' ? "3faac850744b4148" : "90d08146b99b2543"}
            id={props.id || "logitar3-map"}
            {...props.mapProps}
        >
            {
                props.jobs?.map((job) => {
                    const markers: { lat: number, lng: number, radius: number }[] = [];
                    if(job.item.departure.coordinates) {
                        markers.push({
                            lat: parseFloat(job.item.departure.coordinates.split(',')[0]),
                            lng: parseFloat(job.item.departure.coordinates.split(',')[1]),
                            radius: job.item.departure.radius ?? 0
                        });
                    }
                    if(job.item.arrival.coordinates) {
                        markers.push({
                            lat: parseFloat(job.item.arrival.coordinates.split(',')[0]),
                            lng: parseFloat(job.item.arrival.coordinates.split(',')[1]),
                            radius: job.item.arrival.radius ?? 0
                        });
                    }
                    return markers.map((marker, index) => {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: marker.lat, lng: marker.lng }}
                            />
                        )
                    });
                })
            }
            {
                (props.enableRoutes && selectedJob && depCoords && arrCoords) && (
                    <MapRoutes 
                        mapId={"logitar3-map"}
                        route={{
                            origin: {
                                lat: parseFloat(depCoords[0]),
                                lng: parseFloat(depCoords[1])
                            },
                            destination: {
                                lat: parseFloat(arrCoords[0]),
                                lng: parseFloat(arrCoords[1])
                            }
                        }}
                    />
                )
            }
            {
                props.vehicles?.map((vehicle) => {
                    return (
                        <VehicleMarker
                            key={vehicle.vehicle + ":" + vehicle.licenseNumber}
                            vehicle={vehicle}
                            isSelected={false}
                            isDriver={false}
                        />
                    )
                })
            }
            {
                (props.vehiclePath && props.vehiclePath.length > 0) &&
                <Polyline
                    path={props.vehiclePath.map(e => ({ lat: e.latitude, lng: e.longitude }))}
                    strokeColor={theme.palette.mode === 'light' ? mapThemes.pathLight : mapThemes.pathDark}
                    strokeOpacity={1}
                    strokeWeight={3}
                />
            }

            {
                props.mapLabel && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 16,
                            left: 16,
                            padding: 8,
                            background: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: 8,
                            color: 'white',
                            ...props.mapLabelStyle
                        }}
                    >
                        {props.mapLabel}
                    </div>
                )
            }

        </Map>
    );
}