import { useEffect, useState } from "react";
import { GetVehicleLocationsResponse, VehicleLocation, VehicleLocationPath } from "../../api/LogitarApiTypes";
import { Box, Card, Slider, Tooltip, Typography, keyframes } from "@mui/material";
import { format } from "date-fns";
import { Check, Download, Login, Logout, Upload } from "@mui/icons-material";
import { inProgressColor, readyColor } from "../../views/MapView";

type VehicleTimelineProps = {

    show: boolean;

    //vehicles: VehicleLocation[];
    selectedVehicle?: VehicleLocationPath;

    selectedTimestamp?: number;

    onTimestampChange?: (timestamp: number, index: number) => void;
}

export enum MapTimelineEventType {
    LoadStart,
    LoadEnd,
    UnloadStart,
    UnloadEnd,
    WorkStart,
    WorkEnd
}

export const JobEventTypeLabels = ["Lastaus alku", "Lastaus loppu", "Purku alku", "Purku loppu", "Vuoron aloitus", "Vuoron lopetus"];

const VehicleTimelineEventLabelTooltip = (props: {type: MapTimelineEventType, data: any}) => {

    let time = new Date(props.data.date);
    let eventLabel = "";
    let hasTime = true;
    switch(props.type) {
        case MapTimelineEventType.LoadStart:
            time = new Date(props.data.gpsLoadStart + "Z");
            eventLabel = props.data.item.shortName;
            break;
        case MapTimelineEventType.LoadEnd:
            time = new Date(props.data.gpsLoadEnd + "Z");
            eventLabel = props.data.item.shortName;
            break;
        case MapTimelineEventType.UnloadStart:
            time = new Date(props.data.gpsUnloadStart + "Z");
            eventLabel = props.data.item.shortName;
            break;
        case MapTimelineEventType.UnloadEnd:
            time = new Date(props.data.gpsUnloadEnd + "Z");
            eventLabel = props.data.item.shortName;
            break;
        case MapTimelineEventType.WorkStart:
            time = new Date(props.data.startTime);
            eventLabel = props.data.user + " - " + props.data.userName;
            break;
        case MapTimelineEventType.WorkEnd:
            time = new Date(props.data.endTime);
            eventLabel = props.data.user + " - " + props.data.userName;
            break;
        default:
            hasTime = false;
            break;
    }
    
    // Check if time is invalid
    if(isNaN(time.getTime())) {
        hasTime = false;
        time = new Date(props.data.date);
    }

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card sx={{p: 0.25, mr: 1}}>
                <Typography variant="caption" sx={{p: 0, m: 0}}>
                    {JobEventTypeLabels[props.type]}
                </Typography>
            </Card>
            <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <Typography variant="caption" sx={{p: 0, m: 0, lineHeight: '1em'}}>
                    { format(time, "dd.MM.yyyy") }
                </Typography>
                {
                    hasTime &&
                    <Typography variant="caption" sx={{p: 0, m: 0, lineHeight: '1em'}}>
                            { format(time, "HH:mm:ss") }
                    </Typography>
                }
            </Box>
        </Box>
        
        <Typography variant="caption">
            {eventLabel}
        </Typography>
    </Box>
}

export function VehicleTimelineEventLabel (props: {type: MapTimelineEventType, data: any, onClickTimestamp?: (time: number) => void}) {

    let time = new Date(props.data.date);
    let icon = <Check />;
    let hasTime = true;
    switch(props.type) {
        case MapTimelineEventType.LoadStart:
            time = new Date(props.data.gpsLoadStart + "Z");
            break;
        case MapTimelineEventType.LoadEnd:
            time = new Date(props.data.gpsLoadEnd + "Z");
            break;
        case MapTimelineEventType.UnloadStart:
            time = new Date(props.data.gpsUnloadStart + "Z");
            break;
        case MapTimelineEventType.UnloadEnd:
            time = new Date(props.data.gpsUnloadEnd + "Z");
            break;
        case MapTimelineEventType.WorkStart:
            time = new Date(props.data.startTime);
            break;
        case MapTimelineEventType.WorkEnd:
            time = new Date(props.data.endTime);
            break;
        default:
            hasTime = false;
            break;
    }
    // Check if time is invalid
    if(isNaN(time.getTime())) {
        hasTime = false;
        time = new Date(props.data.date);
    }
    
    switch(props.type) {
        case MapTimelineEventType.LoadStart:
            icon = <Download sx={{color: inProgressColor}} />;
            break;
        case MapTimelineEventType.LoadEnd:
            icon = <Download sx={{color: readyColor}} />;
            break;
        case MapTimelineEventType.UnloadStart:
            icon = <Upload sx={{color: inProgressColor}} />;
            break;
        case MapTimelineEventType.UnloadEnd:
            icon = <Upload sx={{color: readyColor}} />;
            break;
        case MapTimelineEventType.WorkStart:
            icon = <Login sx={{color: inProgressColor}} />;
            break;
        case MapTimelineEventType.WorkEnd:
            icon = <Logout sx={{color: readyColor}} />;
            break;
    }

    return <Tooltip title={<VehicleTimelineEventLabelTooltip {...props} />}>
        <Box
            sx={{
                cursor: props.onClickTimestamp ? 'pointer' : 'auto'
            }}
            onClick={() => {
                if(props.onClickTimestamp && hasTime) {
                    props.onClickTimestamp(time.getTime());
                }
            }}
        >
            {icon}
        </Box>
    </Tooltip>
}

// Define the keyframes for the animation
const slideUp = keyframes`
  from {
    display: none;
    transform: translateY(100%);
  }
  to {
    display: block;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    display: block;
    transform: translateY(0);
  }
  to {
    display: none;
    transform: translateY(100%);
  }
`;

export default function VehicleTimeline (props: VehicleTimelineProps) {

    const [timelineTimestamps, setTimelineTimestamps] = useState<{value: number, label: string}[]>([]);

    const [selectedTimestamp, setSelectedTimestamp] = useState<number | null>(null);

    const [timelineEvents, setTimelineEvents] = useState<{value: number, label: React.ReactNode}[]>([]);

    useEffect(() => {
        if(props.selectedTimestamp) {
            setSelectedTimestamp(props.selectedTimestamp);
            triggerTimestampChange(props.selectedTimestamp);
        }
    }, [props.selectedTimestamp]);

    useEffect(() => {

        if(!props.selectedVehicle) {
            setTimelineTimestamps([]);
            return;
        }

        let jobEvents: {value: number, label: React.ReactNode}[] = [];

        // Get all job events
        setTimelineEvents([...props.selectedVehicle.jobs.flatMap((job, i) => {
            let evs: {value: number, label: React.ReactNode}[] = [];
            if(job.gpsLoadStart) {
                const d = new Date(job.gpsLoadStart + "Z");
                evs.push({value: d.getTime(), label: <VehicleTimelineEventLabel type={MapTimelineEventType.LoadStart} data={job} />});
            }
            if(job.gpsLoadEnd) {
                const d = new Date(job.gpsLoadEnd + "Z");
                evs.push({value: d.getTime(), label: <VehicleTimelineEventLabel type={MapTimelineEventType.LoadEnd} data={job} />});
            }
            if(job.gpsUnloadStart) {
                const d = new Date(job.gpsUnloadStart + "Z");
                evs.push({value: d.getTime(), label:<VehicleTimelineEventLabel type={MapTimelineEventType.UnloadStart} data={job} />});
            }
            if(job.gpsUnloadEnd) {
                const d = new Date(job.gpsUnloadEnd + "Z");
                evs.push({value: d.getTime(), label:<VehicleTimelineEventLabel type={MapTimelineEventType.UnloadEnd} data={job} />});
            }
            return evs;
        }),
        ...props.selectedVehicle.workhours.flatMap((work, i) => {
            let evs: {value: number, label: React.ReactNode}[] = [];
            if(work.startTime) {
                const d = new Date(work.startTime);
                evs.push({value: d.getTime(), label: <VehicleTimelineEventLabel type={MapTimelineEventType.WorkStart} data={work} />});
            }
            if(work.endTime) {
                const d = new Date(work.endTime);
                evs.push({value: d.getTime(), label: <VehicleTimelineEventLabel type={MapTimelineEventType.WorkEnd} data={work} />});
            }
            return evs;
        })]);

        // Get all timestamps
        let timestamps: {value: number, label: string}[] = [];
        props.selectedVehicle.positions.forEach((location, i) => {
            const d = new Date(location.dateTime + "Z");
            timestamps.push({value: d.getTime(), label: ""});
        });

        setTimelineTimestamps(timestamps);
        if(timestamps.length > 0) {
            setSelectedTimestamp(timestamps[timestamps.length - 1].value);
        }
        else {
            setSelectedTimestamp(null);
        }

    }, [props.selectedVehicle]);

    const triggerTimestampChange = (timestamp: number) => {
        // Find closest timestamp
        const val = timestamp;
        let closest = timelineTimestamps[0];
        let closestDiff = Math.abs(timelineTimestamps[0].value - val);
        let closestIndex = 0;
        timelineTimestamps.forEach((t, i) => {
            if(Math.abs(t.value - val) < Math.abs(closest.value - val)) {
                closest = t;
                closestDiff = Math.abs(t.value - val);
                closestIndex = i;
            }
        });

        setSelectedTimestamp(closest.value);
        if(props.onTimestampChange) {
            props.onTimestampChange(val, closestIndex);
        }
    }

    return <Box
        sx={{
            position: "absolute",
            bottom: 0,
            left: '30vw',
            right: '25vw',
            overflow: 'hidden',
            p: 2,
            px: 4,
            pt: 4,
            pointerEvents: 'none'
        }}
    >
        <Box
            sx={{
                background: 'rgba(0, 0, 0, 0.5)',
                p: 1,
                px: 2,
                m: 1,
                height: 58,
                borderRadius: 29,
                transform: props.show ? 'translateY(0%)' : 'translateY(100%)',
                animation: `${props.show ? slideUp : slideDown} 0.2s ease-in-out forwards`
            }}
        >
            <Box
                sx={{
                    color: 'primary.contrastText',
                    m: 0,
                    ml: 1,
                    mt: -0.8,
                    mb: -1,
                    p: 0,
                    fontSize: '0.5em',
                    lineHeight: '0.5em',
                }}
            >
                <Typography
                    variant="caption"
                >
                    {props.selectedVehicle?.vehicle.id ?? ''} - {props.selectedVehicle?.vehicle.licenseNumber ?? ''}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        ml: 2
                    }}
                >
                    {selectedTimestamp ? format(selectedTimestamp, "dd.MM.yyyy HH:mm") : '-'}
                </Typography>
            </Box>
            {
                timelineTimestamps.length === 0 &&
                <Typography
                    sx={{
                        color: 'primary.contrastText',
                        m: 1,
                        mr: 1
                    }}
                >
                    Ei sijaintitietoja
                </Typography>
            }
            {
                timelineTimestamps.length > 0 &&
                <Slider 
                    min={timelineTimestamps[0].value}
                    max={timelineTimestamps[timelineTimestamps.length - 1].value}
                    value={selectedTimestamp || timelineTimestamps[0].value}
                    marks={timelineEvents}
                    step={60000}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value, i) => format(value, "dd.MM HH:mm")}
                    slotProps={{
                        markLabel: {
                            style: {
                                marginTop: -12,
                                paddingTop: 0
                            }
                        }
                    }}
                    onChange={(e, value) => {
                        // Find closest timestamp
                        const val = value as number;
                        triggerTimestampChange(val);
                    }}
                    sx={{
                        pointerEvents: 'auto'
                    }}
                />
            }
            
        </Box>
    </Box>
}