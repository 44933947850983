import { Badge, Box, Chip, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { BusinessCenter, Email, Label, LocalShipping, NoAccounts } from '@mui/icons-material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar'
import UserEdit, { emailNotificationTypes } from '../components/UserEdit'
import { localeText } from '../misc/LocaleText'
import { LogiTarUserTypeInfo } from '../misc/User'
import { contentBoxStyle } from '../styles/styles'
import PersistentDataGridPro from '../components/persistent/PersistentDataGridPro'
import { useUserConfig } from '../misc/UserConfig'

/** @type {import('@mui/x-data-grid').GridColDef[]} */
const columns = [
    { field: 'id', headerName: 'Työntekijänumero', width: 160 },
    { field: 'username', headerName: 'Käyttäjätunnus', width: 150 },
    { field: 'name', headerName: 'Nimi', width: 300 },
    { field: 'userType', headerName: 'Tyyppi', width: 150, valueGetter: (param) => param.value ? LogiTarUserTypeInfo.find(y => y.userType === param.value).label : "" },
    { field: 'passive', headerName: 'Passiivinen', align: "center", renderCell: (params) => params.row.passive ? <NoAccounts /> : null },
]

/** @type {import('@mui/x-data-grid').GridColDef[]} */
const widerColumns = [
    { field: 'id', headerName: 'Työntekijänumero', width: 160 },
    { field: 'username', headerName: 'Käyttäjätunnus', width: 150 },
    { field: 'name', headerName: 'Nimi', width: 300 },
    { field: 'userType', headerName: 'Tyyppi', width: 150, valueGetter: (param) => param.value ? LogiTarUserTypeInfo.find(y => y.userType === param.value).label : "" },
    { field: 'email', headerName: 'Sähköposti', width: 200 },
    { field: 'phoneNumber', headerName: 'Puhelinnumero', width: 140 },
    { field: 'driverID', headerName: 'Kuljettajan ID', width: 140 },
    { field: 'costCentre', headerName: 'Oma tulospaikka', width: 100 },
    {
        field: 'vehicles', headerName: 'Autot', width: 100,
        renderCell: (param) => param.value && <TooltipListIconBadge icon={<LocalShipping />} list={param.value.map(v => `${v.id}: ${v.licenseNumber}`)} />
    },
    {
        field: 'emailFlags', headerName: 'Sähköpostihälytykset', width: 100,
        renderCell: (param) => param.value && <TooltipListIconBadge icon={<Email />} list={JSON.parse(param.value).map(v => emailNotificationTypes.flatMap(g => g.options).find(t => t.id === v).label)} />
    },
    {
        field: 'clients', headerName: 'Asiakkaat', width: 100,
        renderCell: (param) => param.value && <TooltipListIconBadge icon={<BusinessCenter />} list={param.value.sort().map(v => {
            const client = param.row.clientsList.find(c => c.id === v);
            if (client) {
                return `${client.id}: ${client.name}`;
            } else return "";
        })} />
    },
    {
        field: 'items', headerName: 'Nimikkeet', width: 100,
        renderCell: (param) => param.value && <TooltipListIconBadge icon={<Label />} list={param.value.sort().map(v => {
            const item = param.row.itemsList.find(i => i.id === v);
            if (item) {
                return `${item.id}: ${item.name}`;
            } else return "";
        })} />
    },
    { field: 'language', headerName: 'Kieli', width: 80, renderCell: (param) => <Chip sx={{ fontFamily: 'monospace' }} size='small' label={param.value} /> },
    { field: 'passive', headerName: 'Passiivinen', align: "center", renderCell: (params) => params.row.passive ? <NoAccounts /> : null },
]

export default function Users() {
    /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
    const userConfig = useUserConfig('users');

    const [rows, setRows] = useState([])
    const [popup, setPopup] = useState(false)
    const [user, setUser] = useState({})
    //Count for handling grid update
    const [count, setCount] = useState(0)
    const [createNew, setCreateNew] = useState(false)
    const [showPassive, setShowPassive] = useState(userConfig.config.showPassive != null ? userConfig.config.showPassive : false);
    const [expandList, setExpandList] = useState(userConfig.config.viewType === 'expanded' ? true : false);

    const apiRef = useGridApiRef();

    //Create an empty User to the user to be filled
    const handleNewUser = () => {

        setUser({
            username: "", password: "", name: "", userType: 0, permissions: 0, email: "", phoneNumber: "", passive: 0
        })
        setPopup(true)
        setCreateNew(true)
    }

    useEffect(() => {
        let getExtent = "list"
        if (expandList) {
            getExtent = "all"
        }
        if (getExtent === "list") {
            LogitarApi.getUsers({ extent: getExtent }).then((result) => {
                if (!result.users) {
                    return
                } else {
                    setRows(result.users);
                }
            })
            .catch((err) => console.error(err))
        }
        else if (getExtent === "all") {
            Promise.all([
                LogitarApi.getUsers({ extent: getExtent }),
                LogitarApi.getClients({ extent: "list" }),
                LogitarApi.getItems({ extent: "list" })
            ]).then(res => {
                if (res[0].users && res[1].clients && res[2].items) {
                    const clients = res[1].clients;
                    const items = res[2].items;
                    setRows(res[0].users.map(u => {
                        const uClients = clients.filter(c => u.clients.some(uc => uc === c.id));
                        const uItems = items.filter(i => u.items.some(ui => ui === i.id));
                        return { ...u, clientsList: uClients, itemsList: uItems };
                    }))
                }
            }).catch((err) => console.error(err))
        }
        


    }, [count])

    const handlePopupUser = (params) => {
        //TODO: user edit / create screen
        if (!params.row.id) {
            console.log("Can't open an empty User")
        }

        LogitarApi.getUsers({ id: params.row.id, extent: "all" }).then((result) => {
            if (result.status) {
                setUser({ ...result.users[0], password: "" })
                setPopup(true)
                setCreateNew(false)
            }
        })
            .catch((err) => console.error(err))
    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"
            },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%'
        }}>
            <PersistentDataGridPro
                userConfig={userConfig}
                apiRef={apiRef}
                rows={!showPassive ? rows.filter(e => e.passive === 0) : rows.filter(e => e.passive === 1)}
                columns={expandList ? widerColumns : columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick={true}
                onRowClick={(params) => { handlePopupUser(params) }}
                slots={{
                    toolbar: () => <CustomToolbar
                        userConfig={userConfig}
                        text={"Uusi käyttäjä"}
                        onClick={handleNewUser}
                        passiveFilter={showPassive}
                        onPassiveFilter={(c) => {
                            setShowPassive(c);
                            userConfig.config = { ...userConfig.config, showPassive: c };
                            apiRef.current.setColumnVisibility("passive", c);
                        }}
                        expandList={expandList}
                        onExpandList={(c) => {
                            setExpandList(c);
                            userConfig.config = { ...userConfig.config, viewType: c ? 'expanded' : 'normal' }
                            setCount(count + 1);
                        }}
                    />
                }}
                localeText={localeText}
                loading={!rows[0]}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }]
                    },
                    columns: {
                        columnVisibilityModel: { passive: showPassive }
                    }
                }}
                density='compact'
                slotProps={toolbarSlotProps}
            />

            {popup &&
                <UserEdit user={user} onClick={setPopup} new={createNew} rowUpdate={setCount} />
            }
        </Box>
    )
}

/** @param {{ icon: import('react').ReactNode, list: string[] }} props  */
function TooltipListIconBadge(props) {
    if (props.list.length < 1) {
        return "";
    }
    return (
        <Tooltip title={props.list.map((v, i) => <Box key={i}>{v}</Box>)}>
            <Badge
                slotProps={{ badge: { sx: { translate: "-10% 20%" } } }}
                sx={{ translate: "0 15%" }}
                badgeContent={props.list.length}
                color='primary'>
                {props.icon}
            </Badge>
        </Tooltip>
    )
}