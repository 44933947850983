import { createTheme } from "@mui/system";
import { softrainTheme, softrainThemeDark } from "./Themes/SoftrainTheme";
import { konnekuljetusTheme, konnekuljetusThemeDark } from "./Themes/KonnekuljetusTheme";
import { tarkkalaTheme, tarkkalaThemeDark } from "./Themes/TarkkalaTheme";
import Config from "./Config";

const variants = {
    softrain: softrainTheme,
    tarkkala: tarkkalaTheme,
    konnekuljetus: konnekuljetusTheme
}

const darkVariants = {
    softrain: softrainThemeDark,
    tarkkala: tarkkalaThemeDark,
    konnekuljetus: konnekuljetusThemeDark
}

/**
 * @type {'light'|'dark'}
 */
export let logitarThemeMode = localStorage.getItem("THEME_MODE") || "light";

/**
 * @brief Current theme
 * @readonly
 */
export let logitarTheme = (logitarThemeMode === 'light' ? variants : darkVariants)[Config.getBranch()];

/**
 * 
 * @param {'light'|'dark'} mode 
 */
export const setThemeMode = (mode) => {
    logitarThemeMode = mode;
    if(mode === 'light') {
        logitarTheme = variants[Config.getBranch()];
    }
    else {
        logitarTheme = darkVariants[Config.getBranch()];
    }
    localStorage.setItem("THEME_MODE", mode);
}