import { Autocomplete, Box, TextField } from "@mui/material";
import { DataGridPro, gridFilteredSortedRowEntriesSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { format, subDays } from "date-fns";
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from "react-to-print";
import LogitarApi from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import { localeText } from "../misc/LocaleText";
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import { contentBoxStyle } from "../styles/styles";
import { ClientReportPrintArea, ExportToolbar, FooterCustomTotalRows, calculateReportTotals, columns, totalsColumns } from "./ClientReportGrid";

/**
 * @typedef {{id: string, label: string, children?: ClientReportsSelect[]}} ClientReportsSelect
 */
export default function SubcontractorReports() {
    const isContractor = LogiTarUser.current.info.userType === LogiTarUserType.CONTRACTOR;
    
    const [report, setReport] = useState([]);

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(!isContractor ?
        [] :
        [{ label: LogiTarUser.current.info.name, id: LogiTarUser.current.info.id }]
    );
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [allUsersSelected, setAllUsersSelected] = useState(true);
    const [allVehiclesSelected, setAllVehiclesSelected] = useState(true);

    // Internal setting to configure how contractors get reported jobs
    /** @type {"vehicle" | "user" | "both"} */
    const filterBy = isContractor ? "vehicle" : "both";

    const printArea = useRef();

    const apiRef = useGridApiRef();

    const [printTriggered, setPrintTriggered] = useState(false);
    const [reportIds, setReportIds] = useState([]);

    const userAutoCompleteRef = useRef();
    const vehicleAutoCompleteRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printArea.current,
        onAfterPrint: () => { setPrintTriggered(false) }
    })

    useEffect(() => {
        if (printTriggered) {
            handlePrint();
        }
    }, [printTriggered]);

    const [fetching, setFetching] = useState(true);

    const [dateRange, setDateRange] = useState([subDays(new Date, 14), new Date()]);

    const [totals, setTotals] = useState({});

    const [initialFetch, setInitialFetch] = useState(true);

    useEffect(() => {

        if (initialFetch)
            return;

        setFetching(() => true)

        LogitarApi.getClientReport({
            start: format(dateRange[0], "yyyy-MM-dd"),
            end: format(dateRange[1], "yyyy-MM-dd"),
            users: filterBy === "user" || filterBy === "both" ? selectedUsers.map((e) => e.id) : undefined,
            vehicles: filterBy === "vehicle" || filterBy === "both" ? selectedVehicles.map((v) => v.id) : undefined,
        }).then((r) => {
            console.log(r)
            setReport(r.report.filter(r => r.loadTime !== null));
            apiRef.current.autosizeColumns({columns: ["itemName"], includeHeaders: true, includeOutliers: true});
            setFetching(() => false)
        })

    }, [dateRange, selectedUsers, selectedVehicles, initialFetch])

    useEffect(() => {
        if (!isContractor) {
            LogitarApi.getUsers({ userType: LogiTarUserType.CONTRACTOR }).then((r) => {
                const _users = r.users.map((e) => {
                    return {
                        id: e.id,
                        label: e.id + ": " + e.name
                    }
                })
                _users.sort((a, b) => a.id - b.id);
                setUsers(_users);
                setSelectedUsers(_users);
                LogitarApi.getUserVehicles([r.users.map(u => u.id)]).then(r => {
                    const _vehicles = [];
                    r.users.forEach(u => {
                        u.vehicles.forEach(v => {
                            if (!_vehicles.map(v => v.id).includes(v.id)) {
                                _vehicles.push({label: `${v.id}: ${v.licenseNumber}`, id: v.id});
                            }
                        })
                    });
                    _vehicles.sort((a, b) => a.id - b.id);
                    setVehicles(_vehicles);
                    setSelectedVehicles(_vehicles);
                }).catch(err => console.error(err)).finally(() => setInitialFetch(false))
            })
                .catch((e) => {
                    console.error(e)
                })
        }
        else {
            LogitarApi.getUserVehicles([LogiTarUser.current.info.id]).then(res => {
                setSelectedVehicles(res.users[0].vehicles.map(v => ({label: `${v.id}:${v.licenseNumber}`, id: v.id})));
            }).catch(err => console.error(err)).finally(() => setInitialFetch(false))
        }
    }, []);

    useEffect(() => {
        setAllUsersSelected(selectedUsers.length === users.length);
        setAllVehiclesSelected(selectedVehicles.length === vehicles.length);
    }, [selectedUsers, selectedVehicles]);

    return (
        <>
            <Box sx={{ ...contentBoxStyle, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} className={"report-grid"}>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <DateRangeSelect 
                        value={dateRange}
                        onChange={(val) => {
                            setDateRange(val);
                        }}
                    />
                    {
                        LogiTarUser.current.info.userType !== LogiTarUserType.CONTRACTOR &&
                        <>
                        <Autocomplete
                            options={[{ id: -1, label: !allUsersSelected ? "Valitse kaikki" : "Poista valinnat" }, ...users]}
                            value={selectedUsers}
                            size="small"
                            multiple
                            limitTags={5}
                            onChange={(e, v) => {
                                if (v.some((e) => e.id === -1)) {
                                    if (allUsersSelected) {
                                        setSelectedUsers([]);
                                    }
                                    else {
                                        setSelectedUsers(users);
                                        userAutoCompleteRef.current.blur();
                                    }
                                }
                                else {
                                    setSelectedUsers(v)
                                }
                            }}
                            sx={{
                                ml: 1,
                                flex: 1
                            }}
                            renderInput={(params) => <TextField inputRef={userAutoCompleteRef} label="Alihankkijat" {...params} />}
                        />
                        <Autocomplete
                            options={[{ id: -1, label: !allVehiclesSelected ? "Valitse kaikki" : "Poista valinnat" }, ...vehicles]}
                            value={selectedVehicles}
                            size="small"
                            multiple
                            limitTags={5}
                            onChange={(e, v) => {
                                if (v.some((e) => e.id === -1)) {
                                    if (allVehiclesSelected) {
                                        setSelectedVehicles([]);
                                    }
                                    else {
                                        setSelectedVehicles(vehicles);
                                        vehicleAutoCompleteRef.current.blur();
                                    }
                                }
                                else {
                                    setSelectedVehicles(v)
                                }
                            }}
                            sx={{
                                ml: 1,
                                flex: 1
                            }}
                            renderInput={(params) => <TextField inputRef={vehicleAutoCompleteRef} label="Alihankkijoiden autot" {...params} />}
                        />
                        </>
                    }
                </Box>

                <Box sx={{display: "flex", flexDirection: "column", height: "calc(100% - 56px)"}}>
                    <DataGridPro
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    id: false
                                },
                            },
                        }}
                        slots={{
                            toolbar: ExportToolbar
                        }}
                        slotProps={{
                            toolbar: { 
                                onPrint: (ids) => { setReportIds(ids); setPrintTriggered(true) }
                            }
                        }}
                        unstable_headerFilters
                        columns={columns}
                        rows={report}
                        loading={fetching}
                        cursor="pointer"
                        density="compact"
                        localeText={localeText}
                        hideFooter
                        sx={{flexGrow: 1}}
                        onStateChange={() => setTotals(calculateReportTotals(gridFilteredSortedRowEntriesSelector(apiRef)))}
                        apiRef={apiRef}
                    />
                    <DataGridPro
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    id: false
                                },
                            },
                        }}
                        slots={{
                            toolbar: () => null,
                            // columnHeaders: () => null,
                            footerRowCount: () => FooterCustomTotalRows(gridFilteredSortedRowEntriesSelector(apiRef).length),
                        }}
                        columns={totalsColumns}
                        rows={[{
                            id: report.length,
                            field: "totals",
                            name: "Yhteensä",
                            m3: totals.m3,
                            tons: totals.tons,
                            count: totals.count,
                            waitTime: totals.waitTime,
                            MWh: totals.MWh,
                            kilometres: totals.kilometres,
                            }, {
                            id: report.length + 1,
                            field: "avgs",
                            name: "Keskiarvo",
                            m3: totals.avgM3,
                            tons: totals.avgTons,
                            count: totals.avgCount,
                            waitTime: totals.avgWaitTime,
                            MWh: totals.avgMWh,
                            kilometres: totals.avgKilometres,
                            }
                        ]}
                        loading={fetching}
                        cursor="pointer"
                        density="compact"
                        localeText={localeText}
                        getRowClassName={(params) => `report-${params.row.field}`}
                        style={{maxHeight: "164.5px", borderTop: "1px solid gray"}}
                    />
                </Box>

            </Box>
            <ClientReportPrintArea
                report={report}
                ref={printArea}
                show={printTriggered}
                ids={reportIds}
                dateRange={dateRange}
            />
        </>

    )
}