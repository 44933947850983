import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { PlanningJobInfo, PlanningItem } from "../misc/LogitarTypes";
import { reqs as itemRequires } from "../components/PlanningCarRow";

export default function PlanningSlotTooltip({ job }: { job: PlanningJobInfo }) {

    return (
        <Box sx={{textAlign: "center"}}>
            <h2>{`${job.item.name} - ${job.item.cargoLabel}`}</h2>
            <Table size="small"  className="default-grey" sx={{borderRadius: 2}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Pyydetty</TableCell>
                        <TableCell>Täytetty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(itemRequires).map((field: string, key) => {

                            const f = field as keyof PlanningItem;

                            if (!job.item[f]) {
                                return null;
                            }
                            else if (f === "talenomVariants" && job.item[f].length < 1) {
                                return null;
                            }

                            const fInJ = itemRequires[field].inJob as keyof PlanningJobInfo
                            const label = itemRequires[field].label;

                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={key+job.id}
                                >
                                    <TableCell>{label}</TableCell>
                                    <TableCell>{job[fInJ]}</TableCell>
                                </TableRow>
                            );
                        })
                    }
                    {/* {
                        job.talenomVariant &&
                        <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
                            key={job.id + "talenomVariant"}
                        >
                            <TableCell>Tuotenumero</TableCell>
                            <TableCell>{job.talenomVariant}</TableCell>
                        </TableRow>
                    } */}
                </TableBody>
            </Table>
        </Box>
    )
}