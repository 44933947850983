import { Autocomplete, Box, BoxProps, Button, LinearProgress, ListItemText, ListItemIcon, MenuItem, Popover, PopoverPosition, Slider, Stack, Switch, SxProps, TextField, Tooltip, Typography, SvgIcon, Menu, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useCallback, useContext, useRef, memo, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { addDays, format, intervalToDuration, isBefore, isSameDay } from "date-fns";
import { Clear, Undo, CallSplit, Save, Send } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { useSnackbar } from 'notistack';
import LogitarApi from "../api/LogitarApi";
import { LogitarItem } from "../api/LogitarApiTypes";
import { CustomPopper, TipButton, getTime } from "../misc/InternalFeatures";
import { DragItem, PlanningItem, PlanningJobInfo, PlanningJobSplit, PlanningOrder, PlanningRow, PlanningSlot } from "../misc/LogitarTypes";
import { PLANNING_SLOT_INITIAL_COUNT, SendingContext, UpdateContext } from "../views/Planning";
import AlertPop from "./AlertPop";
import PlanningDriverSelector from "./PlanningDriverSelector";
import PlanningSlotTooltip from './PlanningSlotTooltip';

const carHeaderStyle: SxProps = {
    minWidth: 130,
    fontSize: 12,
    mt: 1,
    mr: 1,
    fontWeight: 'bold',

}

const carTextStyle: any = {
    mb: 0,
    width: 110,
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "black"
        }
    },
    height: 48,
    mr: 0
}

const rowStyle: SxProps = {
    display: 'flex',

    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    height: "auto",
    gap: "4px 8px",
}

const normalize = (n: number) => {
    if (n > 12) {
        return 100;
    }
    return n / 12 * 100;
}

export const logoPaths: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/logo-softrain.png",
    },
    "konnekuljetus": {
        path: "./logos/logo-konnekuljetus.png"
    },
    "tarkkala": {
        path: "./logos/logo-tarkkala.svg"
    },
}

export const logoPathsSmall: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/small/logo-softrain-small.png"
    },
    "konnekuljetus": {
        path: "./logos/small/logo-konnekuljetus-small.png"
    },
    "tarkkala": {
        path: "./logos/small/logo-tarkkala-small.svg"
    },
}
export const reqs: { [key: string]: { inJob: string, label: string } } = {
    "reqTons": { inJob: "tons", label: "Tonnit", },
    "reqM3": { inJob: "m3", label: "Kuutiot", },
    "reqCount": { inJob: "count", label: "Kpl", },
    "reqHours": { inJob: "hours", label: "Tunnit" },
    "reqWaitTime": { inJob: "waitTime", label: "Odotus", },
    "reqKm": { inJob: "kilometres", label: "Kilometrit", },
    "reqDetails": { inJob: "jobDetails", label: "Lisätiedot" },
    "talenomVariants": { inJob: "talenomVariant", label: "Laji" },
}

function PlanningRowSlot(props: { slot: PlanningSlot, dragItem?: DragItem | null, interClient?: string, onStartDrag: (e: DragEvent) => void }) {

    const { slot } = props;
    const interClient = props.interClient;

    const [info, setInfo] = useState<string | null>(null);
    const [job, setJob] = useState<PlanningJobInfo | null>(slot.job ? { ...slot.job } : null);

    const [isSelected, setIsSelected] = useState(false);
    const autocompleteRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();

    const update = useContext(UpdateContext);
    const sending = useContext(SendingContext);

    const [options, setOptions] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [contextMenu, setContextMenu] = useState<PopoverPosition | null>(null);
    const [splitting, setSplitting] = useState<PopoverPosition | null>(null);

    const [dragging, setDragging] = useState<boolean>(false);
    
    const getInitialSplitInfo = () => {
        return {
            date: format(slot.shift === 'A' ? new Date(slot.date) : addDays(new Date(slot.date), 1), "yyyy-MM-dd"),
            shift: slot.shift === 'A' ? "I" : "A",
            hoursRatio: 50,
            switchLocation: "",
            vehicle: slot.vehicle,
        } as PlanningJobSplit
    }
    const [splitInfo, setSplitInfo] = useState<PlanningJobSplit>(getInitialSplitInfo())

    const [deleteConf, setDeleteConf] = useState(false);

    //False is not split, right is has been split to this shift, left is split from this shift 
    const [splitState, setSplitState] = useState<"left" | "right" | false>(
        job?.jobSplit?.id ?
            (job?.jobSplit.date === slot.date &&
                job?.jobSplit.shift === slot.shift) ?
                "right" : "left" : false
    )

    useEffect(() => {        

        setJob(props.slot.job ? { ...props.slot.job } : null);

        if (!props.slot.job) return;

        //Handle new information passed on to this component
        const newInfo = props.slot.job

        setInfo(newInfo.planDetails);

        const splitDelInDraft = newInfo.draft && newInfo.draft['>hasJobSplit'] === 3

        if (newInfo.jobSplit && newInfo.jobSplit.id && !splitDelInDraft) {
            setSplitInfo(newInfo.jobSplit)
            setSplitState(newInfo.jobSplit.date !== slot.date || newInfo.jobSplit.shift !== slot.shift ? "left" : "right")
        }
        else {
            setSplitInfo(getInitialSplitInfo());
            setSplitState(false);
        }

        if (sending)
            sending(() => false)

    }, [props.slot, props.slot.job, props.slot.job?.jobSplit])

    useEffect(() => {
        if (isSelected && autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
    }, [isSelected])

    const clearCreatedPlan = (send: boolean, slot: PlanningSlot) => {

        if (!send) {
            setDeleteConf(false);
            return;
        }

        if (!slot.job) return;

        let d: any = {
            id: slot.job.id,
            ">deleted": true
        }
        if (interClient) {
            d = {
                ...d,
                icOrder: true,
                interClient: interClient
            }
        }
        sending(() => true)

        LogitarApi.setJobs(d).then((result) => {
            setDeleteConf(false);
            if (!slot.job) return;

            console.log(result);
            update(d);
            //If clear successful, reset this slot
            if (slot.job.state === 0) {
                setJob(null);
            } else {
                setJob({ ...slot.job, draft: { ">deleted": true } });
            }
            setInfo(null);
            sending(() => false);

        })
            .catch((err) => console.error(err))
    }

    const undoDelete = () => {
        if (!job)
            return;

        let d = {
            id: job.id,
            ">deleted": false
        }

        LogitarApi.setJobs(d).then((result) => {
            enqueueSnackbar("Kuorma palautettu");
        })
            .catch((err) => {
                enqueueSnackbar("Kuorman palautus epäonnistui", { variant: "error" });
            });
    }

    //Send "details" as soon as its saved
    const handleInfoSave = () => {
        if (!job)
            return;

        let d: any = {
            id: job.id,
            planDetails: info
        }
        if (interClient) {
            d = {
                ...d,
                icOrder: true,
                interClient: interClient
            }
        }

        LogitarApi.setJobs([d]).then((result) => {
            console.log(result)
            handleInfoClose()
        })
            .catch((err) => console.error(err))

    }

    const handleInfoClose = () => {
        setAnchorEl(null);
    }

    const selectChange = (event: React.DragEvent | React.ChangeEvent | null, item: DragItem | null, slot: PlanningSlot) => {

        console.log(event, item, slot);

        if (!item)
            return;

        sending(() => true);

        if (event) event.preventDefault();

        let tempPlan: any = {
            id: item.job ? item.job.id : undefined,
            date: slot.date,
            shift: slot.shift,
            item: item.item.id,
            vehicle: slot.vehicle,
            slot: slot.slot,
            ">forceUpdate": (item.job && item.job.id && item.job.state === 0) ? true : false
        }

        if(item.job && item.job.interClient) {
            delete tempPlan.item;
        }

        if (autocompleteRef.current)
            autocompleteRef.current.blur();

        LogitarApi.setJobs([tempPlan]).then((result) => {
            setOptions([]);
            update(tempPlan);
            sending(() => false);
        }).catch((err) => {
            console.error(err)        
            if (err.error === 0x43) {
                enqueueSnackbar("Kuorma numerolla jo tietokannassa, päivitetään ruutu....", { variant: "error" });
                update(err);     
            } else {
                enqueueSnackbar("Virhe tallentaessa kuormaa", { variant: "error" });
            }
            sending(() => false);
        })
    }

    const handleRowContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        setContextMenu({ top: e.clientY, left: e.clientX })
    }

    const searching = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (e.target.value.trim() === "") {

        } else {
            const delayDebounce = setTimeout(() => {
                /** @ts-ignore must be ignored because getItems params are defined wrong*/
                LogitarApi.getItems({ search: String(e.target.value) })
                    .then((result) => {
                        if (result.items.length === 0) {

                        } else {
                            setOptions(result.items.map((value: LogitarItem) => {
                                return { name: `${value.id} ${value.name} ${value.cargoLabel}`, id: value.id, active: value.active }
                            }))
                        }
                    })
                    .catch((e) => console.error(e))

            }, 1000)

            return () => clearTimeout(delayDebounce)
        }

    }, [])

    const handleJobSplit = () => {
        if (!job)
            return;

        //Prohibit inserting to the same shift
        if (splitInfo.date === slot.date && splitInfo.shift === slot.shift) {
            enqueueSnackbar("Et voi jakaa kuormaa samalle vuorolle", { variant: "error" })
            return
        }

        LogitarApi.setJobSplit({ ...splitInfo, jobID: job.id, jobUser: job.user }).then((r) => {

            if (!job)
                return;

        })
            .catch((e) => console.log(e))

        setSplitting(null)
    }

    const deleteJobSplit = () => {

        if (!job)
            return;

        if (!splitInfo.id) {
            return
        }

        LogitarApi.setJobSplit({ ...splitInfo, jobUser: job.user, jobID: job.id }, true).then((r) => {
            setSplitInfo(getInitialSplitInfo())
            console.log(r)
        })
            .catch((e) => console.log(e))
    }

    const handleJobKeyInput = (event: KeyboardEvent) => {
        if (event.key === "Tab" || event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (autocompleteRef.current.value) {
                const nextSlotExists = slot.slot < PLANNING_SLOT_INITIAL_COUNT - 1;
                const searchId = !isNaN(autocompleteRef.current.value) ? autocompleteRef.current.value : null;
                /** @ts-ignore must be ignored because getItems params are defined wrong*/
                if (searchId) LogitarApi.getItems({id: Number(searchId)}).then(result => {
                        if (result.status && result.items.length > 0) {
                            selectChange(null, { item: result.items[0] }, slot)
                            if (nextSlotExists) {
                                const nextElement = document.evaluate(`//*[@data-testid="planning-slot"][@data-slotinfo="${slot.vehicle};${slot.slot + 1}"]`, document).iterateNext() as HTMLElement;
                                nextElement.click();
                            } else {
                                const sendButton = document.evaluate(`//*[@data-testid="send-vehicle"][@data-vehicle="${slot.vehicle}"]`, document).iterateNext() as HTMLElement;
                                sendButton.focus();
                            }
                        }
                }).catch(err => {
                    enqueueSnackbar(`Nimikettä ${searchId} ei löytynyt`, { variant: "error" });
                    console.error(err);
                });
            }
        }
    }

    const isDeleted = !!job && job.draft && job.draft[">deleted"] ? true : false;

    if (!!job && !isDeleted) {

        let logoPath = "";
        let logoStyle = {};

        if (job.interClient && logoPathsSmall[job.interClient]) {
            logoPath = logoPathsSmall[job.interClient].path;
            logoStyle = logoPathsSmall[job.interClient].style || {};

        }

        const icBg: SxProps = {

            "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: `linear-gradient(to bottom right, inherit 50%, transparent 50%)`,
                zIndex: -1
            },
            position: "relative",
            height: "100%",
            background: `linear-gradient(to bottom right, inherit 50%, transparent 50%), url('${logoPath}') no-repeat bottom right`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            ...logoStyle
        }

        let slotStyle = "default-red";

        if(job.state === 0 || (!!job.draft && !Array.isArray(job.draft) && Object.keys(job.draft).length > 0)) {
            // Red job if is unsent or any draft is set
            slotStyle = "default-red";
        }
        else if(job.state === 1) {
            // Yellow job if sent
            slotStyle = "default-yellow";
        }
        else if(job.state === 2) {
            // Green job if seen
            slotStyle = "default-green";
        }
        else if(job.state === 3) {
            // Blue job if loaded
            slotStyle = "default-blue";
        }
        else if(job.state === 4) {
            // Grey job if completed
            slotStyle = "";
        }

        // carTextStyle can't be null but TS seems to think it can be
        if (!carTextStyle)
            return null;

        const isDraggable = !(splitState !== false);

        return (
            <Box
                sx={{
                    ...carTextStyle, display: "flex", flexDirection: "row", pr: 0,
                    "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                    "& .MuiOutlinedInput-root": { pr: 0.5 },
                }}
                onContextMenu={handleRowContextMenu}
                component={"div"}
            >
                <Tooltip sx={{ fontSize: 22 }} placement='right' title={!dragging ? <PlanningSlotTooltip job={job}/> : ''}>
                    <Box
                        data-testid="planning-slot"
                        data-slotinfo={`${slot.vehicle};${slot.slot}`}
                        sx={{
                            borderWidth: 1,
                            borderColor: '#cccccc',
                            borderStyle: 'solid',
                            borderRadius: 1,
                            px: 0.5,
                            py: 0.1,
                            height: carTextStyle.height,
                            width: carTextStyle.width - 24,
                            flexDirection: "row",
                            display: "flex",
                            textAlign: 'center',
                            clipPath: splitState === "left"
                                ? "polygon(50% 0%, 90% 0%,99% 50%, 90% 100%,50% 100%, 0% 100%,0% 50%, 0% 0%)" :
                                splitState === "right" ?
                                    "polygon( 50% 0%, 100% 0%, 100% 50%, 100% 100%, 50% 100%, 10% 100%, 1% 50%,  10% 0%)" : "",
                            ...(job.interClient ? icBg : {})
                        }}
                        className={!isDeleted ? slotStyle : "default-red"}
                        draggable={isDraggable}
                        onDragStart={(e) => {
                            if(!isDraggable) return;

                            setDragging(true);

                            props.onStartDrag(e as unknown as DragEvent)
                        }}
                        onDragEnd={() => {
                            setDragging(false);
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "0.68rem", overflow: "hidden", overflowWrap: "break-word", cursor: "pointer" }}
                            onClick={() => {
                                // Open new tab in `items?id=${pInfo.item.id}`
                                window.open(`items?id=${job.item.id}`, "_blank");
                            }}
                        >
                            {
                                isDeleted &&
                                <s>
                                    {job.item.id}:{job.item.shortName}
                                </s>
                            }
                            {
                                !isDeleted &&
                                <>
                                    {job.item.id}:{job.item.shortName}
                                </>
                            }
                        </Typography>

                        {
                            job.interClient &&
                            <img 
                                src={logoPath} 
                                style={{ width: 12, height: 12, position: 'absolute', right: 4, bottom: 4 }} 
                                alt={job.interClient} 
                            />
                        }
                    </Box>
                </Tooltip>
                <Box sx={{ display: "flex", flexDirection: "column" }}>

                    {splitState !== 'right' && 
                        <Clear 
                        data-testid="delete-job"
                        sx={{ cursor: "pointer" }} 
                        onClick={job.state > 2 ? () => setDeleteConf(true) : () => clearCreatedPlan(true, slot)}
                        />
                    }

                    <div className={info && info.length > 0 ? 'default-green' : ""} style={{ borderRadius: 3, height: "50%" }}>
                        <DescriptionIcon onClick={(e) => { setAnchorEl(e.currentTarget) }} sx={{ cursor: "pointer" }}></DescriptionIcon>
                    </div>

                </Box>

                <Popover
                    sx={{ ml: 3, borderRadius: 1 }}
                    open={anchorEl !== null}
                    anchorEl={anchorEl}
                    anchorReference="anchorEl"
                    onClose={handleInfoClose}
                    slotProps={{root: {onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleInfoClose();
                    }}, paper: {onContextMenu: (e) => {
                        e.stopPropagation();
                    }}}}
                >
                    <Box sx={{ p: 2, width: 450 }}>
                        <Typography sx={{ p: 1 }}>Syötä lisätiedot</Typography>
                        <TextField onChange={(e) => setInfo(e.target.value)} value={info || ""} multiline rows={3} sx={{ p: 2, width: "100%" }} />
                        <Button sx={{ width: "48%", mr: 1 }} variant='contained' onClick={handleInfoSave}>Tallenna</Button>
                        <Button sx={{ width: "48%", ml: 1 }} variant='contained' onClick={handleInfoClose}>Peruuta</Button>
                    </Box>
                </Popover>

                <Menu
                    onClose={() => {
                        setContextMenu(null);

                    }}
                    anchorReference={"anchorPosition"}
                    open={contextMenu !== null}
                    anchorPosition={contextMenu || undefined}
                    slotProps={{root: {onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setContextMenu(null);
                    }}, paper: {onContextMenu: (e) => {
                        e.stopPropagation();
                    }}}}
                >
                    <MenuItem onClick={(e) => { setSplitting({ top: e.clientY, left: e.clientX }); setContextMenu(null) }} >
                        <ListItemIcon>
                            <CallSplit sx={{ transform: "rotate(90deg)" }} ></CallSplit>
                        </ListItemIcon>
                        <ListItemText>Jaa</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={splitState !== 'left'} onClick={() => {setContextMenu(null); deleteJobSplit() }}>
                        <ListItemIcon>
                            <Clear sx={{ color: "red" }}></Clear>
                        </ListItemIcon>
                        <ListItemText>Poista jako</ListItemText>
                    </MenuItem>
                </Menu>

                <Popover
                    onClose={() => {
                        setSplitting(null);

                    }}
                    anchorReference={"anchorPosition"}
                    open={splitting !== null}
                    anchorPosition={splitting || undefined}
                    slotProps={{root: {onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSplitting(null);
                    }}, paper: {onContextMenu: (e) => {
                        e.stopPropagation();
                    }}}}
                >
                    <Stack sx={{ px: 2.5, width: "400px", py: 1 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6">Kuorman jakaminen</Typography>
                            <Clear sx={{ cursor: "pointer" }} onClick={() => setSplitting(null)} />
                        </Box>
                        <Typography>{job.item.id}:{job.item.name}</Typography>
                        <Box sx={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", my: 1 }} >
                            <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                <Stack>
                                    <DesktopDatePicker
                                        format='dd.MM.yyyy'
                                        label="Lastauspäivä"
                                        value={splitState === "right" && job.date ? new Date(job.date) : new Date(slot.date)}
                                        disabled                                        
                                        sx={{ height: 30, "& .MuiOutlinedInput-root ": { height: 30 } }}
                                    ></DesktopDatePicker>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography>Aamu</Typography>
                                        <Switch disabled checked={splitState === "right" && job.shift ? job.shift === "I" : slot.shift === "I"}></Switch>
                                        <Typography>Ilta</Typography>
                                    </Box>

                                </Stack>
                                <Stack>

                                    <DesktopDatePicker
                                        format='dd.MM.yyyy'
                                        label="Purkupäivä"
                                        value={new Date(splitInfo.date)}
                                        disabled={splitState === "right"}
                                        minDate={slot.shift === "I" ? addDays(new Date(slot.date), 1) : new Date(slot.date)}
                                        onChange={(v) => {
                                            if (!v || !v.getTime())
                                                return

                                            let shift: "A" | "I" = splitInfo.shift
                                            
                                            // Stop shift from being the same as jobs shift
                                            if (!!job.date && isSameDay(new Date(job.date), v) ) {
                                                shift = "I"
                                            }

                                            setSplitInfo(prev => ({ ...prev, shift: shift, date: format(v, "yyyy-MM-dd") }))
                                            
                                        }}
                                        sx={{ height: 30, "& .MuiOutlinedInput-root ": { height: 30 } }}
                                    ></DesktopDatePicker>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography>Aamu</Typography>
                                        <Switch disabled={splitState === "right"} checked={splitInfo.shift === "A" ? false : true} onChange={(e) => {

                                            // If splits date is the same as jobs, then the shift can only by "I" if the jobs shift is "A"                                            
                                            if (!!job.date &&
                                                (format(new Date(splitInfo.date), "yyyy-MM-dd") === format(new Date(job.date), "yyyy-MM-dd") &&
                                                (splitInfo.shift === "I" && !e.target.checked) || (splitInfo.date === "A" && !e.target.checked))) {
                                                enqueueSnackbar("Et voi jakaa kuormaa samalle vuorolle", { variant: "error" })
                                                setSplitInfo(prev => ({ ...prev, shift: "I" }))
                                                return
                                            }
                                            
                                            setSplitInfo(prev => ({ ...prev, shift: e.target.checked ? "I" : "A" }))
                                        }}
                                        ></Switch>
                                        <Typography>Ilta</Typography>
                                    </Box>
                                </Stack>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Tooltip title={"Nimikkeen keston prosenttijakauma"}>
                                    <div>{`${splitInfo.hoursRatio}%`}</div>
                                </Tooltip>
                                <Tooltip title={"Nimikkeen kokonaiskesto"}>
                                    <div>{getTime(Number(job.item.hours))}</div>
                                </Tooltip>
                            </Box>
                            <Slider
                                disabled={splitState === "right"}
                                value={splitInfo.hoursRatio}
                                onChange={(e: Event, value: number | number[]) => {
                                    if (typeof value === "number")
                                        setSplitInfo(prev => ({ ...prev, hoursRatio: value }));
                                }}
                                size="small"
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Tooltip title={"Viejän tunnit"}>
                                    <div>{getTime(Number(job.item.hours) * splitInfo.hoursRatio / 100)}</div>
                                </Tooltip>
                                <Tooltip title={"Hakijan tunnit"}>
                                    <div>{getTime(Number(job.item.hours) - Number(job.item.hours) * splitInfo.hoursRatio / 100)}</div>
                                </Tooltip>
                            </Box>
                        </Box>
                        <TextField
                            label="Kuljettan vaihtopaikka"
                            value={splitInfo.switchLocation}
                            disabled={splitState === "right"}
                            onChange={(e) => setSplitInfo(prev => ({ ...prev, switchLocation: e.target.value }))}>
                        </TextField>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <div />
                            <TipButton disabled={splitState === "right"} title={"Tallenna"} icon={Save} onClick={() => handleJobSplit()}
                            ></TipButton>
                        </Box>
                    </Stack>
                </Popover>
                {deleteConf &&
                    <AlertPop
                        stopContextMenuPropagate
                        onClose={(action) => clearCreatedPlan(action, slot)}
                        cancellable={true}
                        title={"Haluatko varmasti poistaa kuorman?"}
                        content={
                            <div>
                                <Box sx={{ py: 1 }}>
                                    <Typography variant="h6">
                                        {job.item.id}:{job.item.name}
                                    </Typography>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: 200 }}>
                                        <div>
                                            Auto:
                                        </div>
                                        <div>
                                            {slot.vehicle}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: 200 }}>
                                        <div>
                                            Kuorma nro:
                                        </div>
                                        <div>
                                            {slot.slot + 1}
                                        </div>
                                    </div>
                                </Box>
                                <div>
                                    Täytetyt tiedot:
                                    {Object.keys(reqs).map((r) => {

                                        if (r === "talenomVariants" && job.item[r].length > 0 || Boolean(Number(job.item[r as keyof PlanningItem]))) {
                                            return (
                                                <div style={{ display: "flex", justifyContent: "space-between", width: 200, gap: 16 }}>
                                                    <div>
                                                        {reqs[r].label}
                                                    </div>

                                                    <div>
                                                        {job[reqs[r].inJob as keyof PlanningJobInfo]}
                                                    </div>
                                                </div>

                                            )
                                        }
                                        return null
                                    })}
                                </div>

                            </div>
                        }
                    />
                }
            </Box>
        )
    } else {
        if (!isSelected) {

            return (
                <Box sx={{
                    display: "flex", flexDirection: "row"
                }}>

                    <Box
                        data-testid="planning-slot"
                        data-slotinfo={`${slot.vehicle};${slot.slot}`}
                        sx={{
                            ...carTextStyle, pr: 0,
                            "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                            "& .MuiOutlinedInput-root": { pr: 0 },
                            borderRadius: "4px",
                            textAlign: 'center',
                            border: '1px solid rgba(0,0,0,0.33)',
                            width: !!job ? carTextStyle.width - 24 : carTextStyle.width,

                        }}
                        onDrop={(e) => {
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        onClick={(e) => {
                            setIsSelected(true);
                        }}

                        className={!job ? "" : "default-red"}
                    >

                        {
                            !!job &&
                            <Typography sx={{ fontSize: "0.68rem", overflow: "hidden", overflowWrap: "break-word" }} >
                                <s>
                                    {job.item.shortName}
                                </s>
                            </Typography>
                        }


                    </Box>
                    {
                        !!job &&
                        <Undo 
                            data-testid="undo-delete"
                            sx={{ cursor: "pointer" }} 
                            onClick={() => undoDelete()} 
                        />
                    }
                </Box>
            )
        }
        return (
            <Autocomplete
                data-testid="planning-slot"
                data-slotinfo={`${slot.vehicle};${slot.slot}`}
                options={options}
                freeSolo
                disableClearable={true}
                getOptionLabel={(option) => option.name}
                PopperComponent={CustomPopper}
                size="small"

                onChange={(e, values) => selectChange(e as ChangeEvent<Element>, {item: values}, slot)}
                componentsProps={{
                    paper: {
                        elevation: 10,
                    },
                }}

                renderOption={(props, option) => (
                    <Box
                        {...props as BoxProps}
                        component="div"
                        sx={option.active ? {} : {opacity: 0.5, textDecoration: "line-through", position: "relative"}}
                    >
                        {option.name}
                        {!option.active &&
                            <Box sx={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}} onClick={(e) => {window.open(`items?id=${option.id}&passive`, "_blank"); e.stopPropagation()}}/>
                        }
                    </Box>
                )}

                renderInput={(params) =>
                    <TextField
                        {...params}
                        onChange={searching}

                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: 0,
                            },

                        }}

                        onBlur={() => setIsSelected(false)}

                        inputRef={autocompleteRef}

                        onDrop={(e) => {
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        type="search"
                        sx={{ ...carTextStyle }}
                        size='small'
                        
                        onKeyDown={handleJobKeyInput}
                    />}
            />
        )
    }
}

const MemoizedSlot = memo(PlanningRowSlot, (prev, next) => {
    if(prev.dragItem !== next.dragItem) return false;
    if (!prev.slot.job && !next.slot.job) return true;
    if (!prev.slot.job || !next.slot.job) return false;

    return prev.slot.job.state === next.slot.job.state &&
        prev.slot.date === next.slot.date &&
        prev.slot.shift === next.slot.shift &&
        prev.slot.job.draft === next.slot.job.draft;       
});

export interface PlanningCarRowProps {
    planRowInfo: PlanningRow;
    dragItem?: DragItem | null;
    interClient?: string;
    onStartDrag: (e: DragEvent, slot: PlanningSlot) => void;
}


function PlanningCarRow(props: PlanningCarRowProps) {

    const [hours, setHours] = useState({ total: 0.0, completed: 0.0 });
    const [planRow, setPlanRow] = useState(props.planRowInfo);
    const update = useContext(UpdateContext);

    const theme = useTheme();
    const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));
    const smBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));

    const getModifiedValue = () => {
        let modified = false;
        planRow.slots.forEach((slot) => {
            if (slot.job !== null) {
                if (slot.job.state > 0) {
                    if (slot.job.draft && !Array.isArray(slot.job.draft) && Object.keys(slot.job.draft).length > 0) {
                        modified = true;
                    }
                } else {
                    modified = true;
                }
            }
        })
        return modified;
    }
    const [modified, setModified] = useState(getModifiedValue());

    //Send cars plans
    const sendCars = () => {

        LogitarApi.setJobs(undefined, {...planRow.shiftInfo, vehicle: planRow.vehicle.id}).then((r) => {
            console.log(r);            
        })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        let hoursTemp = { total: 0, completed: 0 }
        const planRowInfo = props.planRowInfo;
        planRowInfo.slots.filter((slot) => !!slot.job).forEach((slot) => {
            if (!slot.job) return;

            hoursTemp.total += Number(slot.job.item.hours);
            if (slot.job.state === 4) {
                hoursTemp.completed += Number(slot.job.item.hours);
            }
        })

        setHours(() => hoursTemp)
        setPlanRow(planRowInfo);
        setModified(getModifiedValue());
    }, [props.planRowInfo])

    const zeroPad = (num: number) => String(num).padStart(2, '0')

    let workHoursTime = null;
    let workHoursTooltip = null;
    if (planRow && planRow.vehicle && planRow.vehicle.workHours && planRow.vehicle.workHours.startTime) {
        const startTime = new Date(planRow.vehicle.workHours.startTime);
        let wht: Duration | null = null;
        if (planRow.vehicle.workHours.endTime) {
            const endTime = new Date(planRow.vehicle.workHours.endTime);
            wht = intervalToDuration({ start: startTime, end: endTime });
            workHoursTooltip = (<ul style={{ padding: 0, margin: 0 }}>
                <li>Alku: {format(startTime, "dd.MM HH:mm")}</li>
                <li>Loppu: {format(endTime, "dd.MM HH:mm")}</li>
            </ul>);
        }
        else {
            wht = intervalToDuration({ start: startTime, end: new Date() });
            workHoursTooltip = (<ul style={{ padding: 0, margin: 0 }}>
                <li>Alku: {format(startTime, "dd.MM HH:mm")}</li>
                <li>Loppu: -</li>
            </ul>);
        }
        if (wht)
            workHoursTime = `${zeroPad(wht.hours || 0)}:${zeroPad(wht.minutes || 0)}h`;
    }

    return (
        <Box
            sx={{
                "& .MuiLinearProgress-dashed": {
                    animation: "none"
                },
                "& 	.MuiLinearProgress-bar1Buffer ": {
                    backgroundColor: "green"
                },
                "& .MuiOutlinedInput-input": {
                    py: 0,
                    pl: 1,
                    height: 40
                }
            }}>
            {planRow &&
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {
                        <Box sx={{ flexDirection: 'column', width: "8.5rem", maxWidth: smBreakPoint ? undefined : 100, display: 'flex', flex: 0.5 }}>
                            <Typography sx={carHeaderStyle}>
                                {planRow.vehicle.id} - {planRow.vehicle.licenseNumber}
                            </Typography>
                            <Typography sx={{ color: '#777', overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} variant="caption">
                                {
                                    planRow.vehicle.currentUser ?
                                        <>
                                            {planRow.vehicle.currentUser} - {planRow.vehicle.currentUserName}
                                        </>
                                        : <>-</>
                                }
                            </Typography>
                            {
                                workHoursTime &&
                                <Tooltip title={workHoursTooltip}>
                                    <Typography sx={{ color: '#777' }} variant="caption">
                                        {workHoursTime}
                                    </Typography>
                                </Tooltip>
                            }
                        </Box>
                    }
                    {
                        /* TODO
                        interClient &&
                        <Typography sx={carHeaderStyle}>
                            {planRow.client.displayName}
                        </Typography>
                        */
                    }

                    <Box sx={{ justifyContent: "space-between", flexDirection: "row", display: "flex" }}>


                        <Box sx={rowStyle}>

                            {
                                planRow.slots.map((slot, key) => {
                                    return <MemoizedSlot 
                                        slot={slot} 
                                        dragItem={props.dragItem} 
                                        key={key}
                                        interClient={props.interClient} 
                                        onStartDrag={(e) => props.onStartDrag(e, slot)}
                                    />
                                })
                            }

                            <LinearProgress
                                sx={{ width: '100%', my: 0.5 }}
                                variant="buffer"
                                value={normalize(hours.completed)}
                                valueBuffer={normalize(hours.total)}
                            />

                        </Box>

                        <Box
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: 12
                                },
                                ml: 2,
                                // minWidth: 200,
                                flexDirection: 'row',
                                display: 'flex',
                                flexWrap: lgBreakPoint ? "nowrap" : "wrap",
                                gap: 1,
                                alignContent: "flex-start",
                            }}
                        >
                            {
                                planRow.vehicle &&
                                <PlanningDriverSelector planRow={planRow} />
                            }
                            <Box
                                sx={{ flexDirection: 'column', minWidth: "100px"}}
                            >
                                {
                                    <Tooltip title={`Lähetä autolle ${planRow.vehicle.id}`}>
                                        <span>
                                            <Button
                                                disabled={!modified}
                                                data-testid="send-vehicle"
                                                data-vehicle={planRow.vehicle.id}
                                                onClick={() => sendCars()}
                                                size="small"
                                                sx={{ px: 0.8, whiteSpace: 'nowrap', width: "4.75rem", height: "1.75rem"}}
                                                variant="contained">
                                                <span style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: "0.3rem", width: "100%", fontSize: "1rem", fontWeight: "bold", lineHeight: "normal"}}>
                                                    {planRow.vehicle.id}
                                                    <SvgIcon component={Send} style={{fontSize: "1.25rem"}}/>
                                                </span>
                                            </Button>
                                        </span>
                                    </Tooltip>
                                }
                                {
                                    <Box
                                        sx={{ p: 0.2, px: 1.5 }}
                                    >

                                    </Box>
                                }

                                <Typography>
                                    Tunnit: {hours.total.toFixed(2)}
                                </Typography>
                                <Typography>
                                    Toteutunut: {hours.completed.toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default PlanningCarRow;