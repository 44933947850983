/**
 * @brief Definition of a LogiTar user
 */

import { FuelType } from "../components/FuelPriceCard";
import { PermissionTemplate, ReadPermissions, WritePermissions } from "./Permissions"

/**
 * @enum {number}
 */
export const LogiTarUserType = {
    UNKNOWN:        0,
    DRIVER:         1,
    CONTRACTOR:     2,
    SERVICE:        3,
    PLANNING:       4,
    BILLING:        5,
    MANAGEMENT:     10,
    CLIENT:         11,    
    DEVELOPER:      200,
    ADMIN:          255
}

/**
 * global BigInt
 */

let _userChangeListenerId = 0;

/**
 * @type {{userType: LogiTarUserType, label: string, description?: string, permissions: bigint}[]}
 */
export const LogiTarUserTypeInfo = [
    { userType: LogiTarUserType.UNKNOWN, label: "", description: "Tuntematon tyyppi", permissions: 0},
    { 
        userType: LogiTarUserType.DRIVER, label: "Kuljettaja", permissions:   PermissionTemplate.PERM_DEF_JOBS | 
                                                                            PermissionTemplate.PERM_DEF_MESSAGES | 
                                                                            WritePermissions.PERM_SETVEHICLES |
                                                                            ReadPermissions.PERM_GETVEHICLES |
                                                                            ReadPermissions.PERM_GETITEMS |
                                                                            ReadPermissions.PERM_GETHOLIDAYS |
                                                                            ReadPermissions.PERM_GETAREAS
    },
    { 
        userType: LogiTarUserType.CONTRACTOR, label: "Alihankkija", permissions: PermissionTemplate.PERM_DEF_JOBS | 
                                                                            PermissionTemplate.PERM_DEF_MESSAGES | 
                                                                            WritePermissions.PERM_SETVEHICLES |
                                                                            ReadPermissions.PERM_GETVEHICLES | 
                                                                            ReadPermissions.PERM_GETITEMS |
                                                                            ReadPermissions.PERM_GETAREAS
    },
    { userType: LogiTarUserType.SERVICE, label: "Huolto", permissions: ReadPermissions.PERM_GETVEHICLES | WritePermissions.PERM_SETVEHICLES | 
                                                                        PermissionTemplate.PERM_DEF_MESSAGES | PermissionTemplate.PERM_DEF_SERVICE
    },
    { userType: LogiTarUserType.PLANNING, label: "Ajojärjestelijä", permissions: PermissionTemplate.PERM_DEF_JOBS |
                                                                            PermissionTemplate.PERM_DEF_MESSAGES |
                                                                            PermissionTemplate.PERM_DEF_SERVICE |
                                                                            ReadPermissions.PERM_GETUSERS |
                                                                            ReadPermissions.PERM_GETVEHICLES |
                                                                            ReadPermissions.PERM_GETITEMS |
                                                                            WritePermissions.PERM_SETITEMS |
                                                                            ReadPermissions.PERM_GETORDERS |
                                                                            WritePermissions.PERM_SETVEHICLES |
                                                                            WritePermissions.PERM_SETORDERS |
                                                                            ReadPermissions.PERM_GETCLIENTS |
                                                                            WritePermissions.PERM_SETCLIENTS |                                                                            
                                                                            WritePermissions.PERM_SETAREAS |
                                                                            ReadPermissions.PERM_GETCARGOS |
                                                                            WritePermissions.PERM_SETCARGOS |
                                                                            WritePermissions.PERM_SETNEWS
    },
    { userType: LogiTarUserType.BILLING, label: "Laskutus", permissions: PermissionTemplate.PERM_DEF_MESSAGES | ReadPermissions.PERM_GETCLIENTS |
                                                                    ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETVEHICLES |
                                                                    WritePermissions.PERM_SETCLIENTS | WritePermissions.PERM_SETJOBS |
                                                                    ReadPermissions.PERM_GETORDERS | ReadPermissions.PERM_GETPLANNING
                                                                 
    },
    { userType: LogiTarUserType.MANAGEMENT, label: "Hallinta", permissions: PermissionTemplate.PERM_DEF_OFFICE },
    { userType: LogiTarUserType.CLIENT, label: "Asiakas", permissions: ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETITEMS | ReadPermissions.PERM_GETVEHICLES },
    { userType: LogiTarUserType.DEVELOPER, label: "Kehittäjä", permissions: ReadPermissions.PERM_GETAPIDOC },
    { userType: LogiTarUserType.ADMIN, label: "Admin", permissions: PermissionTemplate.PERM_DEF_ALL },
]

/* global BigInt */
export default class LogiTarUser {

    token = {
        key: "",
        created: new Date(),
        lifetime: 0,
        expires: new Date(),
        permissions: 0n,
        user: 0,
        username: "",
        server_version: "",
        event_url: ""
    }

    info = {
        id: 0,
        username: "",
        permissions: "",
        name: "",
        userType: 0,
        phoneNumber: "",
        email: "",
        vehicles: [],
        currentShift: null,
        driverID: "",
        costCentre: 0
    }
    /**
     * @type {{id: number, licenseNumber: string, type: number, costCentre: number, name: string, fuelType: FuelType, latitude: number|null, longitude: number|null, speed: number|null}|null}
     */
    vehicle = null;

    /**
     * @type {{id: number, onChange: Function}[]}
     * @private
     */
    _userChangeListeners = [];

    /**
     * @brief Current logged in user
     * @type {LogiTarUser|null}
     */
    static current = null

    constructor(token, info) {
        this.token = {...token, ...{created: new Date(token.created), expires: new Date(token.expires), permissions: BigInt(token.permissions)}}
        this.info = {...info}

        // Pull vehicle from localStorage
        const v = localStorage.getItem("LOGITAR-USER-VEHICLE");
        if(v) {
            this.vehicle = JSON.parse(v);
        }
    }

    /**
     * @brief Checks if user has required permissions
     * @param {bigint} requiredPermissions
     */
    checkPermission (requiredPermissions) {
        const nperm = this.token.permissions & BigInt(requiredPermissions)
        return nperm === requiredPermissions
    }

    isDriver () {
        return this.info.userType === LogiTarUserType.DRIVER || 
                this.info.userType === LogiTarUserType.CONTRACTOR;
    }

    isContractor () {
        return this.info.userType === LogiTarUserType.CONTRACTOR;
    }

    isManagement () {
        return this.info.userType === LogiTarUserType.ADMIN || 
                this.info.userType === LogiTarUserType.MANAGEMENT || 
                this.info.userType === LogiTarUserType.PLANNING;
    }

    setVehicle (vehicle) {
        this.vehicle = vehicle;
        localStorage.setItem("LOGITAR-USER-VEHICLE", JSON.stringify(vehicle));
    }

    /**
     * @brief Add user change listener
     * @param {Function} onChange 
     * @return {number} id
     */
    addChangeListener (onChange) {
        const _id = _userChangeListenerId++;
        this._userChangeListeners.push({id: _id, onChange: onChange});

        return _id;
    }

    /**
     * @brief Remove user change listener
     * @param {number} id 
     */
    removeChangeListener (id) {
        const cix = this._userChangeListeners.findIndex(e => id === e.id);
        if(cix >= 0)
            this._userChangeListeners.splice(cix, 1);
    }

    /**
     * @brief Triggers a change
     */
    triggerChange () {
        this._userChangeListeners.forEach(e => {
            console.log("TRIGGER CHANGE");
            e.onChange();
        })
    }

}