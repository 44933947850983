import { Add, ArrowDropDown, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Button, CircularProgress, Divider, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isAfter } from "date-fns";
import { fi } from "date-fns/locale";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import FuelPriceCard, { fuelTypeList } from "../components/FuelPriceCard";
import MultiTreeSelect from "../components/MultiTreeSelect.tsx";
import { contentBoxStyle } from "../styles/styles";
import { DateRangeSelect } from "../components/DateSelect";
import { LogiTarUserType } from "../misc/User";
import AlertPop from "../components/AlertPop";

const validTimeCutoff = new Date("2024-08-08T00:00:00");

export default function Fuel() {

    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [vehicleMenuOpen, setVehicleMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [users, setUsers] = useState([]);


    const _sdate = new Date();
    _sdate.setDate(_sdate.getDate() - 14);
    const [dateStart, setDateStart] = useState(_sdate);
    const [dateEnd, setDateEnd] = useState(new Date());

    const [fuelData, setFuelData] = useState(/** @type {FuelRow[] | null} */(null));

    const [fetching, setFetching] = useState(false);

    const fuelDataRef = useRef(fuelData);

    const [rowModesModel, setRowModesModel] = useState({});

    const newRowId = useRef(-1);
    const [deleteId, setDeleteId] = useState(null);

    const theme = useTheme();

    /**
     * @type {import("@mui/x-data-grid").GridColDef<any>[]}
     */
    const columns = [
        {
            field: "dateTime",
            headerName: "Päivämäärä",
            width: 160,
            valueFormatter: (params) => {
                if (isAfter(new Date(params.value), validTimeCutoff)) {
                    return format(new Date(params.value), "dd.MM.yyyy HH:mm:ss");
                } else {
                    return format(new Date(params.value), "dd.MM.yyyy");
                }
            },
            type: "dateTime",
            editable: true,
        },
        {
            field: "shift",
            headerName: "Vuoro",
            width: 80,
            type: "singleSelect",
            valueOptions: [{ label: "Aamu", value: "A" }, { label: "Ilta", value: "I" }],
            editable: true,
        },
        {
            field: "vehicle",
            headerName: "Auto",
            width: 100,
            valueFormatter: (params) => {
                return (vehicles.find(e => Number(e.id) === Number(params.value)) || { label: params.value }).label
            },
            type: "singleSelect",
            valueOptions: FilterVehicles(vehicles).map(v => ({ label: v.label, value: v.id })),
            editable: true,
        },
        {
            field: "user",
            headerName: "Kuljettaja",
            width: 150,
            valueFormatter: (params) => {
                return (users.find(e => Number(e.id) === Number(params.value)) || { name: params.value }).name
            },
            type: "singleSelect",
            valueOptions:
                FilterDrivers(users)
                    .map(u => ({ label: `${u.id}: ${u.name}`, value: u.id })),
            editable: true,
        },
        {
            field: "kilometres",
            headerName: "Km",
            width: 100,
            editable: true,
            valueFormatter: (params) => params.value === null ? "0" : params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        {
            field: "fuelType",
            headerName: "Käyttövoima",
            width: 100,
            valueFormatter: (params) => {
                const ft = fuelTypeList.find(e => e.value === params.value);
                if (ft)
                    return ft.label;

                return "";
            },
            type: "singleSelect",
            valueOptions: fuelTypeList.map(ft => ({ label: ft.label, value: ft.value })),
            editable: true,
        },
        {
            field: "fuel",
            headerName: "Polttoaine",
            width: 100,
            editable: true,
            valueFormatter: (params) => params.value === null ? "0" : params.value.toString()
        },
        {
            field: "consumption",
            headerName: "Kulutus (L/100km)",
            width: 140,
            valueFormatter: (params) => params.value !== 0 ? params.value.toFixed(1) : "-"

        },
        {
            field: "actions",
            type: "actions",
            headerName: "Muokkaa/Tallenna/Poista",
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const rowData = fuelData.find(e => e.id === id);
                if (!rowData)
                    return [];

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                //alert("mode " + isInEditMode)

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            color="inherit"
                            onClick={() => {
                                handleCancelClick(id);
                            }}
                        />,

                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={() => {
                                onSaveRow(id);
                            }}
                        />,

                        <GridActionsCellItem
                            icon={<Delete />}
                            label="Delete"
                            sx={{
                                color: theme.palette.error.main,
                            }}
                            disabled
                        />,
                    ]
                }

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        onClick={() => {
                            handleEditClick(id);
                        }}
                        disabled={fuelData.some(f => f.edited)}
                    />,

                    <GridActionsCellItem
                        icon={<Save />}
                        label="Save"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => {
                            onSaveRow(id);
                        }}
                        disabled={true}
                    />,

                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        sx={{
                            color: theme.palette.error.main,
                        }}
                        onClick={() => {
                            setDeleteId(id);
                        }}
                        disabled={fuelData.some(f => f.edited)}
                    />,
                ]
            }
        }
    ];

    useEffect(() => {
        LogitarApi.getVehicles()
            .then((data) => {
                if (data.status) {
                    const cars = data.vehicles.map((e) => {
                        return {
                            id: e.id,
                            label: e.id + ":" + e.licenseNumber
                        }
                    })
                    setVehicles(cars);
                    setSelectedVehicles(cars);
                }
                else {
                    console.error("Failed to fetch vehicles " + data.error);
                }
            })
            .catch((err) => {
                console.error("Failed to fetch vehicles " + err);
            })

        LogitarApi.getUsers()
            .then((data) => {
                if (data.status) {
                    setUsers(data.users);
                }
                else {
                    console.error("Failed to fetch users " + data.error);
                }
            })
            .catch((err) => {
                console.error("Failed to fetch users " + err);
            })

    }, []);

    function handleRowDelete(id) {
        if (id == null) return;
        LogitarApi.setFuel({ id: id }, true).then(r => {
            if (r.status) {
                fetchFuel();
                enqueueSnackbar("Polttoaine poistettu", { variant: "success" });
            }
        }).catch(err => {
            console.error(err);
            enqueueSnackbar("Polttoaineen poistaminen epäonnistui", { variant: "error" });
        }
        )
    }

    function onRowUpdate(newRow) {

        console.log(newRow)
        const rowData = fuelDataRef.current.find(e => e.id === newRow.id);
        if (!rowData)
            return;

        const editedRow = { ...newRow, ...{ edited: undefined } };
        const sendRow = { ...editedRow };
        sendRow.dateTime = format(new Date(sendRow.dateTime), "yyyy-MM-dd HH:mm:ss");
        if (sendRow.id != null && sendRow.id < 0) delete sendRow.id;
        LogitarApi.setFuel(sendRow)
            .then((d) => {
                if (d.status) {
                    fetchFuel();
                    enqueueSnackbar("Polttoaine tallennettu");
                }
                else {
                    enqueueSnackbar("Polttoaineen tallennus epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Polttoaineen tallennus epäonnistui", { variant: 'error' });

            });

        return editedRow;
    }

    function onSaveRow(id) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setFuelData(prev => prev.map(f => (f.id === id && f.edited) ? { ...f, edited: undefined } : f));
    }

    function handleCancelClick(id) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        // Delete new rows on cancel
        if (id < 0) {
            const ndata = fuelData.filter(f => f.id !== id);
            setFuelData(ndata);
            fuelDataRef.current = ndata;
            return;
        }
        //Remove color
        const newRow = fuelData.find((row) => (row.id === id));
        const editedRow = { ...newRow, ...{ edited: undefined } };
        const ndata = fuelData.map((row) => (row.id === editedRow.id ? editedRow : row));
        setFuelData(ndata);
        fuelDataRef.current = ndata;
        return editedRow;
    };

    function handleEditClick(id) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'kilometres' } });
        //Set color        
        const newRow = fuelDataRef.current.find(e => e.id === id);
        const editedRow = { ...newRow, ...{ edited: true } };
        const ndata = fuelData.map((row) => (row.id === editedRow.id ? editedRow : row));
        setFuelData(ndata);
        fuelDataRef.current = ndata;
        return editedRow;
    }

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    function fetchFuel() {
        setFetching(true);

        LogitarApi.getFuelRange(
            // Start date
            format(dateStart, "yyyy-MM-dd"),
            // End date
            format(dateEnd, "yyyy-MM-dd"),
            // Vehicle list
            selectedVehicles.map(e => e.id)
        )
            .then((data) => {
                if (data.status) {

                    const lastKm = {};

                    setFuelData(data.fuel.reverse().map((e) => {
                        let consumption = 0;
                        if (lastKm[e.vehicle]) {
                            consumption = (e.fuel / (e.kilometres - lastKm[e.vehicle])) * 100;
                        }
                        lastKm[e.vehicle] = e.kilometres;
                        return { ...e, consumption: consumption };
                    }).reverse());
                }
                else {
                    console.error("Failed to fetch fuel for range");
                }
                setFetching(false);
            })
            .catch(e => {
                console.error(e);
                setFetching(false);
            })

    }

    useEffect(() => {
        fuelDataRef.current = fuelData;
    }, [fuelData]);

    return <Box sx={{ ...contentBoxStyle, pl: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, overflowX: 'auto' }}>
        <Box sx={{ maxWidth: 'calc(100% - 16px)' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
                <DateRangeSelect
                    value={[dateStart, dateEnd]}
                    onChange={(v) => {
                        if (v[0] && v[1]) {
                            setDateStart(v[0]);
                            setDateEnd(v[1]);
                        }
                    }}
                    labels={{ start: "Ajopvm. alku", end: "Ajopvm. loppu" }}
                />
            </Box>
            <Box sx={{ mt: 1 }}>

                <Button
                    onClick={(e) => { setVehicleMenuOpen(true); setAnchorEl(e.currentTarget) }}
                    disabled={vehicles.length === 0}
                >
                    Autot
                    {
                        vehicles.length === 0 &&
                        <CircularProgress size={15} />
                    }
                    {
                        vehicles.length > 0 &&
                        <ArrowDropDown />
                    }
                </Button>

                <MultiTreeSelect
                    open={vehicleMenuOpen}
                    anchorEl={anchorEl}
                    onClose={() => { setVehicleMenuOpen(false) }}
                    onChange={(value) => {
                        setSelectedVehicles(value);
                    }}
                    options={vehicles}
                    value={selectedVehicles}
                    id="vehicle-select"
                />
                <Button
                    onClick={() => { fetchFuel() }}
                    variant='contained'
                >
                    Hae
                </Button>
            </Box>
            <Divider sx={{ m: 2 }} />
            <Box sx={{ minWidth: '250px' }}>
                {
                    (fuelData && fuelData.length > 0) &&
                    <>
                        <Typography variant="caption" sx={{ m: 1 }}>Muokkaa/tallenna/poista rivejä viimeisen sarakkeen napeilla</Typography>
                        <DataGridPro
                            columns={columns}
                            rows={fuelData}
                            editMode="row"
                            rowHeight={30}
                            disableRowSelectionOnClick={true}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStart={(params) => handleEditClick(params.row.id)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={onRowUpdate}
                            sx={{
                                height: '70vh',
                                ".edited": {
                                    "& .MuiDataGrid-cell": {
                                        backgroundColor: "rgba(255,0,0,0.3)",
                                    },
                                },
                                colorScheme: theme.palette.mode,
                            }}
                            getRowClassName={(params) => {
                                if (params.row.edited) {
                                    return "edited"
                                }
                            }}
                            slots={{
                                toolbar: AddNewToolbar
                            }}
                            slotProps={{
                                toolbar: { fuelData, setFuelData, setRowModesModel, users, vehicles, newRowId }
                            }}
                            loading={fetching}
                        />
                    </>
                }
                {
                    (fuelData && fuelData.length === 0) &&
                    <Typography variant="caption">
                        Ei polttoainetietoja valitulle ajalle tai autoille
                    </Typography>
                }
                {
                    ((!fuelData || fuelData.length === 0) && fetching) &&
                    <CircularProgress size={40} />
                }
            </Box>

        </Box>
        <Box sx={{ maxWidth: '100%' }}>
            <FuelPriceCard
                editable
                sx={{ overflow: 'auto' }}
            />
        </Box>
        {deleteId != null &&
            <AlertPop
                title="Haluatko varmasti poistaa polttoainerivin?"
                cancellable
                onClose={(accepted) => {
                    if (accepted) {
                        handleRowDelete(deleteId);
                        setDeleteId(null);
                    } else {
                        setDeleteId(null);
                    }
                }}
                content={
                    (() => {
                        const dFuel = fuelData.find(f => f.id === deleteId);
                        if (dFuel) {
                            const dVeh = vehicles.find(v => v.id === dFuel.vehicle);
                            const dUser = users.find(u => u.id === dFuel.user);
                            const dDate = format(new Date(dFuel.dateTime), "dd.MM.yyyy HH:mm:ss");
                            const dShift = dFuel.shift === "A" ? "Aamu" : dFuel.shift === "I" ? "Ilta" : "";
                            return <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                <Box>
                                    <Typography>Päivämäärä:</Typography>
                                    <Typography>Vuoro:</Typography>
                                    <Typography>Auto:</Typography>
                                    <Typography>Kuljettaja:</Typography>
                                </Box>
                                <Box>
                                    <Typography>{dDate}</Typography>
                                    <Typography>{dShift}</Typography>
                                    <Typography>{dVeh.label}</Typography>
                                    <Typography>{dUser.name}</Typography>
                                </Box>
                            </Box>
                        }
                    })()
                }
            />
        }

    </Box>
}

function AddNewToolbar(props) {
    const { fuelData, setFuelData, setRowModesModel, users, vehicles, newRowId } = props;

    const handleClick = () => {
        const newId = newRowId.current; // Negative id for new rows, deleted when sent to API
        newRowId.current--;
        setFuelData((oldRows) => [{
            id: newId,
            dateTime: new Date(),
            fuel: 0,
            fuelType: 1,
            kilometres: 0,
            shift: "A",
            user: FilterDrivers(users)[0].id,
            vehicle: FilterVehicles(vehicles)[0].id,
            consumption: 0,
            edited: true,
        }, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'dateTime' },
        }));
    };

    return (
        <GridToolbarContainer sx={{ display: "flex", justifyContent: "end" }}>
            <Button color="primary" startIcon={<Add />} onClick={handleClick} disabled={fuelData.some(f => f.edited)}>
                Lisää rivi
            </Button>
        </GridToolbarContainer>
    );
}

function FilterDrivers(users) {
    return users.filter(u => u.userType === LogiTarUserType.DRIVER || u.userType === LogiTarUserType.CONTRACTOR).filter(u => !u.passive).sort((a, b) => a.id - b.id);
}

function FilterVehicles(vehicles) {
    return vehicles.filter(v => v.type !== 5).sort((a, b) => a.id - b.id);
}