import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { Box, TextField } from "@mui/material";
import { Loader } from "../misc/InternalFeatures";

const notesStyle = {
    ml: 1,
    flex: 5,
    height: "auto",
    pt: 1,
    borderRadius: 2,
}

export default function PlanningNotes() {

    const [notes, setNotes] = useState()

    useEffect(() => {

        LogitarApi.getPlanningNotes().then((r) => {

            setNotes(r.planningnotes || {
                content: ""
            })

        })
            .catch((err) => console.error(err))

    }, [])

    const handleNotes = e => {
        
        setNotes(prev => ({ ...prev, content: e.target.value }))
    }

    const sendNotes = () => {

        const currentNote = { ...notes }

        console.log(currentNote)

        LogitarApi.setPlanningNotes(currentNote).then((r) => {
            console.log(r)
        })
            .catch((err) => console.error(err))
    }


    return (
        <Box sx={notesStyle}>
            {notes ?
                <TextField
                    label="Ohjeet"
                    key={"Notes-component"}
                    multiline
                    
                    sx={{ width: "100%", height: "100%" }}
                    onChange={handleNotes}
                    value={notes.content}
                    inputProps={{
                        resize: "both"
                    }}
                    onBlur={sendNotes}

                >
                </TextField>
                : <Loader height={"100%"}></Loader>}
        </Box>
    )

}