

export const defaultBodyClasses = {
    // Default colors
    "& .default-blue": {
        background: '#b3dbff',
        fill: '#b3dbff'
    },
    "& .default-yellow": {
        background: '#ffff99',
        fill: '#ffff99'
    },
    "& .default-green": {
        background: '#aeeaae',
        fill: '#aeeaae'
    },
    "& .default-red": {
        background: '#ff6d6d',
        fill: '#ff6d6d'
    },
    "& .default-orange": {
        background: '#f2cc8f',
        fill: '#f2cc8f'
    },
    "& .default-grey": {
        background: '#ccc',
        fill: '#ccc'
    },

    "& .default-red-text": {
        color: '#ff6d6d'
    },

    "& .fuel-card": {
        "& .green": {
            background: 'rgb(123, 199, 130)',
            color: 'black',
        },
        "& .yellow": {
            background: 'rgb(212, 224, 130)',
            color: 'black'
        },
        "& .orange": {
            background: 'rgb(231, 132, 92)',
            color: 'black'
        },
        "& .red": {
            background: 'rgb(233, 117, 111)',
            color: 'black'
        }
    },
    "& .service-overdue": {
        backgroundColor: '#f18073',
        "&:hover": { backgroundColor: '#e17063' },
        "&.Mui-hovered": { backgroundColor: '#e17063' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#e17063' },
            "&.Mui-hovered": { backgroundColor: '#e17063' },
        },
    },
    "& .service-serviceSoon": {
        backgroundColor: '#e7e580',
        "&:hover": { backgroundColor: '#d7d570' },
        "&.Mui-hovered": { backgroundColor: '#d7d570' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#d7d570' },
            "&.Mui-hovered": { backgroundColor: '#d7d570' },
        },
    },
    "& .service-ordered": {
        backgroundColor: '#7fc47c',
        "&:hover": { backgroundColor: '#6fb46c' },
        "&.Mui-hovered": { backgroundColor: '#6fb46c' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#6fb46c' },
            "&.Mui-hovered": { backgroundColor: '#6fb46c' },
        },
    },
    "& .service-normal": {
        backgroundColor: 'inherit',
        "&:hover": { backgroundColor: '#0001' },
        "&.Mui-hovered": { backgroundColor: '#0001' },
        "&.MuiDataGrid-row:hover": { backgroundColor: '#0001' },
    },
    "& .service-history": {
        "& .autofutur-normal": {
            backgroundColor: 'inherit',
            color: 'black',
        },
        "& .autofutur-sent": {
            backgroundColor: '#D3d3d3',
            "&:hover": { backgroundColor: '#a5adcc' },
            "&.Mui-hovered": { backgroundColor: '#a5adcc' },
            color: 'black',

        },
        "& .autofutur-resend": {
            backgroundColor: '#ffffed',
            "&:hover": { backgroundColor: '#F0E2B6' },
            "&.Mui-hovered": { backgroundColor: '#F0E2B6' },
            color: 'black',
        },
        "& .service-edit": {
            color: 'black',
            backgroundColor: '#e8faff',
        },
    },
    "& .report-grid": {
        "& .report-totals": {
            fontWeight: "bold",
        },
        "& .report-avgs": {
            fontWeight: "bold",
        },
    },
    '& .MuiDataGrid-footerContainer': {
        height: 32,
        minHeight: 32
    }
}

export const defaultBodyClassesDark = {
    ...defaultBodyClasses,
    // Default colors
    "& .default-blue": {
        background: '#244e75',
        fill: '#244e75'
    },
    "& .default-yellow": {
        background: '#83833f',
        fill: '#83833f'
    },
    "& .default-green": {
        background: '#2e662e',
        fill: '#2e662e'
    },
    "& .default-red": {
        background: '#973131',
        fill: '#973131'
    },
    "& .default-orange": {
        background: '#8f631e',
        fill: '#8f631e'
    },
    "& .default-grey": {
        background: '#414141',
        fill: '#414141'
    },

    "& .default-red-text": {
        color: '#e65050'
    },

    "& .fuel-card": {
        "& .green": {
            background: 'rgb(54, 131, 61)',
            color: 'white'
        },
        "& .yellow": {
            background: 'rgb(142, 153, 68)',
            color: 'white'
        },
        "& .orange": {
            background: 'rgb(159, 85, 55)',
            color: 'white'
        },
        "& .red": {
            background: 'rgb(169, 77, 72)',
            color: 'white'
        }
    },
    "& .service-overdue": {
        backgroundColor: '#471711',
        "&:hover": { backgroundColor: '#572721' },
        "&.Mui-hovered": { backgroundColor: '#572721' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#572721' },
            "&.Mui-hovered": { backgroundColor: '#572721' },
        },
    },
    "& .service-serviceSoon": {
        backgroundColor: '#656430',
        "&:hover": { backgroundColor: '#757440' },
        "&.Mui-hovered": { backgroundColor: '#757440' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#757440' },
            "&.Mui-hovered": { backgroundColor: '#757440' },
        },
    },
    "& .service-ordered": {
        backgroundColor: '#126212',
        "&:hover": { backgroundColor: '#1f6f1f' },
        "&.Mui-hovered": { backgroundColor: '#1f6f1f' },
        "&.MuiDataGrid-row": {
            "&:hover": { backgroundColor: '#1f6f1f' },
            "&.Mui-hovered": { backgroundColor: '#1f6f1f' },
        },
    },
    "& .service-normal": {
        backgroundColor: 'inherit',
        "&:hover": { backgroundColor: '#FFF1' },
        "&.Mui-hovered": { backgroundColor: '#FFF1' },
        "&.MuiDataGrid-row:hover": { backgroundColor: '#FFF1' },
    },
    "& .service-history": {
        "& .autofutur-normal": {
            backgroundColor: 'inherit',
            "&:hover": { backgroundColor: 'inherit', filter: "brightness(125%)" },
            "&.Mui-hovered": { backgroundColor: 'inherit', filter: "brightness(125%)" },
        },
        "& .autofutur-sent": {
            backgroundColor: '#333333',
            "&:hover": { backgroundColor: '#393c4d' },
            "&.Mui-hovered": { backgroundColor: '#393c4d' },
            "&.Mui-selected": { backgroundColor: '#393c4d' },
            color: 'white',
        },
        "& .autofutur-resend": {
            backgroundColor: '#4d4d41',
            "&:hover": { backgroundColor: '#666247' },
            "&.Mui-hovered": { backgroundColor: '#666247' },
            "&.Mui-selected": { backgroundColor: '#666247' },
            color: 'white'
        },
        "& .service-edit": {
            color: 'white',
            backgroundColor: 'grey',
        }
    },
    "& .report-grid": {
        "& .report-totals": {
            fontWeight: "bold",
        },
        "& .report-avgs": {
            fontWeight: "bold",
        },
    }
}