import { Print } from "@mui/icons-material";
import { Autocomplete, Button, Divider, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { subDays } from "date-fns";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import LogitarApi from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import PrintableReportBase from "../components/PrintableReportBase";
import EmissionsOverview from "../components/emissionreports/EmissionsOverview";


export default function EmissionReports() {

    const reportRef = useRef();

    const printAreaRef = useRef();

    const [contWidth, setContWidth] = useState(0);

    const [reportData, setReportData] = useState(/** @type {import("../components/emissionreports/EmissionsOverview").EmissionReportData} */(null));

    const [reportConfig, setReportConfig] = useState({
        range: [
            subDays(new Date(), 14),
            new Date()
        ],
        client: { id: null, label: "Kaikki" }
    })

    const [printTriggered, setPrintTriggered] = useState(false);

    const [clients, setClients] = useState([]);

    const handlePrint = useReactToPrint({
        content: () => printAreaRef.current,
        pageStyle: "@print { size: A4 landscape; }",
        onAfterPrint: () => { setPrintTriggered(false) }
    });

    useEffect(() => {
        if (printTriggered) {
            handlePrint();
        }
    }, [printTriggered]);

    useEffect(() => {

        const postConfig = { ...reportConfig };

        // Change client to a value understood by API
        postConfig.client = postConfig.client.id;

        console.log(postConfig);

        LogitarApi.getEmissionReports({
            from: postConfig.range[0],
            to: postConfig.range[1],
            client: postConfig.client
        })
            .then((resp) => {
                const nReport = resp.report;
                if (nReport.clientName) {
                    // Set client name
                    nReport.clientName = (clients.find(e => e.id == nReport.clientName) || { label: "" }).label;
                }
                setReportData(resp.report);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [reportConfig]);

    useEffect(() => {
        LogitarApi.getClients()
            .then((resp) => {
                setClients([
                    {
                        id: null,
                        label: "Kaikki"
                    },
                    ...resp.clients.map((e, i) => {
                        return {
                            id: e.id,
                            label: e.name
                        }
                    })
                ]);
            })
            .catch((err) => {

            })
    }, [])

    useLayoutEffect(() => {
        setContWidth(reportRef.current.clientWidth)
    }, [reportRef])

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            p: 1
        }}
    >
        {/* Report content */}
        <Box
            sx={{ flex: 1, height: '100%', overflowY: 'hidden' }}
            ref={reportRef}
        >
            {
                contWidth > 0 && reportData &&
                <PrintableReportBase
                    width={printTriggered ? 'a4' : contWidth}
                    ref={printAreaRef}
                >
                    <EmissionsOverview
                        data={reportData}
                    />
                </PrintableReportBase>
            }
        </Box>

        {/* Report settings */}
        <Box
            sx={{
                minWidth: 300
            }}
        >
            <Paper sx={{
                padding: 1,
                m: 1,
                mr: 2,
                "& .MuiTextField-root": {
                    margin: 1
                },
                "& .MuiStack-root": {
                    "& .MuiTypography-root": {
                        mx: 1
                    },
                },
                "& .MuiAutocomplete-root": {
                    margin: 1,
                    "& .MuiTextField-root": {
                        margin: 0
                    }
                }
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        Päästöraportti
                    </Typography>
                    {
                        /*
                            <Typography variant="h6" sx={{ flex: 1, textAlign: 'right', color: '#33b3de' }}>
                                {invoiceData.invoice.clients[invoiceClient].name}
                            </Typography>
                        */
                    }
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Box>
                    <DateRangeSelect
                        sx={{ width: 300 }}
                        value={reportConfig.range}
                        onChange={(val) => {
                            setReportConfig({ ...reportConfig, range: val });
                        }}
                    />
                    <Autocomplete
                        options={clients}
                        value={reportConfig.client}
                        isOptionEqualToValue={(opt, val) => opt.id === val.id}
                        getOptionLabel={(opt) => opt.label}
                        renderInput={(params) => <TextField label={"Asiakaskoodi"} {...params} />}
                        onChange={(e, v) => {
                            setReportConfig({ ...reportConfig, client: v })
                        }}
                        disableClearable
                    />
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        onClick={() => setPrintTriggered(true)}
                    >
                        <Print />
                    </Button>
                </Box>
            </Paper>
        </Box>

    </Box>
} 