import { Add, Delete } from '@mui/icons-material';
import { Box, Fade, Link, Table, Typography, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import FilePopup, { base64PDFToBlobUrl } from '../components/FilePopup.tsx';
import TrainingPop from '../components/TrainingPop';
import { TipButton } from '../misc/InternalFeatures';
import LogiTarUser from '../misc/User';
import { contentBoxStyle } from '../styles/styles';

export default function Training(props) {

    const [rows, setRows] = useState([])
    const [singleTraining, setSingleTraining] = useState()
    const [popup, setPopup] = useState(false)
    const [popWidth, setPopWidth] = useState(window.innerWidth * 0.75)
    const admin = LogiTarUser.current.isManagement()
    const [count, setCount] = useState(0)
    const [createNew, setCreateNew] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [file, setFile] = useState({})

    const theme = useTheme();

    //Popover functions
    const open = Boolean(anchorEl)

    const { enqueueSnackbar, closeSnackbar} = useSnackbar()

    const handleFileClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleFileClose = () => {
        setAnchorEl(null)
        setFile({})
    }
    ///

    let columns = [
        { field: 'header', headerName: 'Otsikko', width: 400 },
        { field: 'link', headerName: 'Linkki', width: 400 },
        { field: 'file', headerName: 'Tiedosto', width: 250 },
        { field: 'dateTime', headerName: 'Päivämäärä', width: 200 },
    ]

    const handleNewTraining = () => {
        setSingleTraining({ header: "", description: "", link: "", dateTime: new Date() })
        setPopup(true)
        setCreateNew(true)
    }

    //Fetch file data for training
    const getTrainingFile = (id) => {
        return new Promise((resolve, reject) => {
            LogitarApi.getFile(id).then((result) => {
                resolve(result.file)
            })
        })
    }

    const handleRemove = (row) => {
        //True parameter is for deleting 
        LogitarApi.setTraining(row, true).then((result) => {
            setCount(count + 1)
            enqueueSnackbar(`Koulutus ${row.header} poistettu`)
            
        })
            .catch(() => enqueueSnackbar(`Koulutuksen ${row.header} poisto epäonnistui`, {variant: "error"}))
    }

    useEffect(() => {
        //Get all training (Training)
        LogitarApi.getTraining({ extent: "list" }).then((result) => {
            setRows(result.training)
        })
            .catch((err) => {
                // alert("Virhe haussa")
                console.error(err)
            })

    }, [count])

    const handlePopupTraining = (rowId) => {
        //Fetch traiing info set to a hook, give it to trainingpopup, row id is supplied

        LogitarApi.getTraining({ id: rowId, extent: "allfile" }).then((result) => {
            if (result.status) {
                setSingleTraining(result.training[0])
                setPopup(true)
                setCreateNew(false)
            }
        })
            .catch((err) => console.error(err))
    }

    const trainingStyle = {
        cursor: "pointer",

    }

    const openFile = (event, fileID, file = null) => {
        if (file) {
            setFile(file)
        } else {
            getTrainingFile(fileID).then((file) => {
                setFile({
                    ...file,
                    data: file.MIME.includes("text") ? file.data : base64PDFToBlobUrl(file.data)
                });
            })
        }
        // setPopup(false)
        handleFileClick(event)
    }

    return (
        <Box sx={{ ...contentBoxStyle, maxHeight: "85vh", overflowY: "auto" }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((value, key) => {
                            return <TableCell sx={{ fontWeight: "bold" }} key={key} width={value.width}>{value.headerName}</TableCell>
                        })}
                        {admin &&
                            <TableCell>

                                <TipButton size="small" variant="contained" icon={Add} onClick={() => handleNewTraining()} title={"Uusi tiedote"}></TipButton>
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Fade timeout={700} in key={row.id}>
                                <TableRow sx={trainingStyle} hover>
                                    <TableCell onClick={() => handlePopupTraining(row.id)}>{row.header}</TableCell>
                                    <TableCell onClick={() => handlePopupTraining(row.id)}><Link rel="noreferrer" target="_blank" href={row.link}>{row.link}</Link></TableCell>
                                    <TableCell sx={{ color: (row.fileName) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", ":hover": { textDecoration: "underline" } }}
                                        onClick={(row.fileID) ? (event) => { openFile(event, row.fileID) } : () => handlePopupTraining(row.id)}
                                    >
                                        {/* <Typography onClick={(row.fileID) ? (event) => { openFile(event, row.fileID) } : () => handlePopupTraining(row.id)}>{row.fileName ? row.fileName : "Ei tiedostoa"}</Typography> */}
                                        <Typography >{row.fileName}</Typography>
                                    </TableCell>


                                    <TableCell onClick={() => handlePopupTraining(row.id)}>{format(new Date(row.dateTime), "dd.MM.yyyy H:mm")}</TableCell>
                                    {admin &&
                                        <TableCell><TipButton onClick={() => handleRemove(row)} icon={Delete} variant="contained" title="Poista"></TipButton></TableCell>
                                    }
                                </TableRow>
                            </Fade>
                        )
                    })}
                </TableBody>
            </Table>

            {file.data && 
                <FilePopup file={file} onClose={handleFileClose} />
            }

            {
                popup &&
                    <TrainingPop onClick={setPopup} admin={admin} openFile={openFile} rowUpdate={setCount} new={createNew} count={count} training={singleTraining}></TrainingPop>
            }

        </Box>
    )
}