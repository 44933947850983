import { Autocomplete, Box, TextField } from "@mui/material"
import LogitarApi from "../api/LogitarApi"
import { useEffect, useRef, useState } from "react";
import { CustomPopper } from "../misc/InternalFeatures";
import { addDays, format } from "date-fns";
import { Clear } from "@mui/icons-material";

const carTextStyle = {
    mb: 0,
    width: 110,
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "black"
        }
    },
    height: 48,
    mr: 0,

}

export default function PlanningDriverSelector({ planRow }) {

    const [isSelected, setSelected] = useState(false)

    //ShiftInfo is the object that contains the info to be sent to the api
    const [shiftUser, setShiftUser] = useState()

    //Shift User is the user that is selected for the shift in the select element
    const [shiftInfo, setShiftInfo] = useState(null);

    const autocompleteRef = useRef();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (isSelected && autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
    }, [isSelected])

    useEffect(() => {
        if (planRow.vehicle.driverShifts.length > 0) {
            console.log(planRow)
            if (!shiftUser || shiftUser.name !== planRow.vehicle.driverShifts[0].nextDriver) {
                setShiftUser({ id: planRow.vehicle.driverShifts[0].user, name: planRow.vehicle.driverShifts[0].nextDriver })
                setShiftInfo({ id: planRow.vehicle.driverShifts[0].id, user: planRow.vehicle.driverShifts[0].user });
            }
            console.log(planRow.vehicle.driverShifts[0])
        } else {
            setShiftUser()
            setShiftInfo(null);
        }
    }, [planRow])

    const selectField = (e) => {
        setSelected(true);
        //Fetch options if not given??
        LogitarApi.getUsers({ extent: "list" }).then((r) => {
            setUsers(r.users.filter((user) => user.userType === 1))
        })
    }

    const onDriverSelect = (e, v) => {
        //send value
        console.log(v)

        if (!v) return

        setShiftUser(v)

        //Send currentUser so that event can be sent
        const cuser = planRow.vehicle.currentUser

        let driverShift

        //If there is not existing shiftInfo, create a new one
        if (!shiftInfo) {
            let date = new Date(planRow.shiftInfo.date)

            let shift = planRow.shiftInfo.shift

            if (shift === "A") {
                shift = "I"
            } else {
                date = addDays(date, 1)
                shift = "A"
            }

            date = format(date, "yyyy-MM-dd")

            driverShift = {
                user: v.id,
                vehicle: planRow.vehicle.id,
                date: date,
                shift: shift
            }

        } else {
            driverShift = { id: shiftInfo.id, user: v.id }
        }

        driverShift = {...driverShift, currentUser: cuser}
    
        console.log(driverShift)
        LogitarApi.setDriverShifts(driverShift).then((r) => {
            if(r.latestIDs && r.latestIDs.length > 0) {
                setShiftInfo(prev => ({ id: prev ? prev.id : r.latestIDs[0], user: driverShift.user }));
            }
        })
            .catch((e) => console.log(e))

    }

    const onClear = () => {
        setShiftUser(undefined)

        LogitarApi.setDriverShifts({ id: shiftInfo.id, user: 0, currentUser: planRow.vehicle.currentUser, vehicle: planRow.vehicle.id }).then(r => {
            console.log("Deleted shift");
            setShiftInfo(null);
            console.log(r)
        })
            .catch((e) => console.log(e))
    }

    if (!isSelected) {
        return (
            <Box
                sx={{
                    ...carTextStyle, display: "flex", flexDirection: "row", pr: 0,
                    "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                    "& .MuiOutlinedInput-root": { pr: 0.5 },
                    borderRadius: "4px",
                    border: '1px solid rgba(0,0,0,0.33)',
                    padding: 0.2,
                    fontSize: "0.8rem",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                component={'fieldset'}
                onClick={selectField}
            >
                <legend style={{ filter: 'opacity(60%)', fontSize: '0.6rem', fontWeight: 400 }}>Seuraava kuljettaja</legend>
                {shiftUser &&
                    <>
                        {shiftUser.name}
                        
                        <Clear fontSize='small' sx={{cursor: "pointer"}} onClick={() => onClear()}></Clear>
                    </>
                }
            </Box>
        )
    }

    return (
        <Autocomplete
            options={users}
            loading={users.length < 1}
            getOptionLabel={(option) => option.id + " " + option.name}
            onChange={onDriverSelect}
            PopperComponent={CustomPopper}
            filterOptions={(options, state) => {
                return options.filter((o) => 
                    o.name.toUpperCase().includes(state.inputValue.toUpperCase()) 
                    || String(o.id).toUpperCase().includes(state.inputValue.toUpperCase())
                )
            }}

            isOptionEqualToValue={option => option.id === shiftUser.id}

            renderInput={params =>
                <TextField
                    {...params}
                    sx={{ ...carTextStyle }}
                    size='small'
                    onBlur={() => { setSelected(false); setUsers([]) }}
                    inputRef={autocompleteRef}
                />
            }
        >

        </Autocomplete>
    )
}