import { Add, AddTask, Assignment, AssignmentTurnedIn, Build, Cancel } from "@mui/icons-material";
import { Card, CircularProgress, Divider, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { ServiceMonitorType } from "../api/LogitarApiTypes";
import { useState } from "react";
import ServiceMonitorPop from "./ServiceMonitorPop";
import LogitarApi from "../api/LogitarApi";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import AlertPop from "./AlertPop";
import InspectionPop from "./InspectionPop";
import ServiceMonitorApprovePop from "./ServiceMonitorApprovePop";
import ServiceMonitorOrderPop from "./ServiceMonitorOrderPop";

/**
 * @brief Clamps t between min, max
 * @param {number} min
 * @param {number} max
 * @param {number} t 
 * @returns {number}
 */
const clamp = (min, max, t) => Math.max(min, Math.min(max, t));

/**
 * @typedef {{
 *  driver: {
 *       id: number,
 *       name: string
 *   } | null,
 *   previous: {
 *      id: number,
 *      done: string,
 *      serviceDoneKm: number
 *   } | null,
 *   id: number,
 *   startDate: string,
 *   type: ServiceMonitorType,
 *   yearInterval: number | null,
 *   firstServiceKm: number | null,
 *   nextServiceKm: number | null,
 *   serviceIntervalKm: number | null,
 *   details: string,
 *   serviceByExt: string | null,
 *   status: number
 * }} ServiceMonitorMonitor
 */

/**
 * @typedef {{
*  vehicle: {
*      id: number,
*      name: string,
*      licenseNumber: string,
*      kilometres: number,
*      inspectionDate: string,
*      inspectionByDate: string,
*      registrationDate: string,
*      type: number
*  },
*  monitors: ServiceMonitorMonitor[]
* }} ServiceMonitorVehicle
*/

/**
 * @typedef {{
 *  data: ServiceMonitorVehicle,
 *  requestRefresh?: () => void,
 *  disableMonitorEdit?: boolean
 * }} ServiceMonitorCardProps
 */

// How much trucks drive per day on average
export const averageKmPerDay = 500;

/**
 * @brief Calculates time in ms or kilometres until next service
 * @param {number} current Current time (ms) or kilometres
 * @param {number} first First service time (ms) or kilometres
 * @param {number} interval Interval of services time (ms) or kilometres
 * @param {number|null} last Last service time (ms) or kilometres
 * @param {boolean?} isYearly Is time based service
 * 
 * @return Time in ms or kilometres until next service
 */
export const calculateNextService = (current, first, interval, last, isYearly = false) => {
    const serviceInterval = interval;
    const lastService = last;
    const lastServiceRounded = lastService === null ? null : (isYearly ? lastService : Math.round((lastService - first) / serviceInterval) * serviceInterval + first);
    const firstService = first;
    const remaining = (current - firstService) % serviceInterval;
    
    let untilService;

    if (lastService === null) {
        // No previous service -> return until first service
        return firstService - current;
    }

    if (current <= firstService) {
        if (lastService >= current - serviceInterval) {
            // Calculate value to next service AFTER first service
            untilService = firstService + serviceInterval - current;
        }
        else {
            // Return until first service
            untilService = firstService - current;
        }
    }
    else if (current - lastServiceRounded >= serviceInterval) {
        // Service is overdue -> show negative value
        untilService = lastServiceRounded + serviceInterval - current;
    }
    else {
        if (lastServiceRounded > current) {
            untilService = serviceInterval - remaining + serviceInterval;
        }
        else {  
            // Calculate normally  
            untilService = serviceInterval - remaining;

            if (isYearly === true) {
                 if (last > (current - remaining)) {               
                    // Day in ms
                    const day2ms = 1000 * 3600 * 24;
                    const untilServiceTmp = serviceInterval - remaining + serviceInterval;
                    const untilServiceRounded = Math.floor(untilServiceTmp / (365.25 * day2ms)) 
                    if (untilServiceRounded <= 3 ) { 
                        untilService = untilServiceTmp ;
                    }
                }
            }
        } 
    } 

    return untilService;
}

/**   
 * 
 * @param {ServiceMonitorCardProps} props 
 * @returns 
 */
export function ServiceMonitorCard(props) {

    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editData, setEditData] = useState(/** @type {import("../api/LogitarApiTypes").GetServiceMonitorRow} */(null));

    const [sendInProgress, setSendInProgress] = useState(false);

    const [cancelOrderMonitor, setCancelOrderMonitor] = useState(null);

    const [inspectionVehicle, setInspectionVehicle] = useState(null);

    const [orderMonitor, setOrderMonitor] = useState(null);
    const [approveMonitor, setApproveMonitor] = useState(null);

    const vehicle = props.data.vehicle;

    const theme = useTheme();

    /**
     * 
     * @param {ServiceMonitorType} type 
     */
    function openEditPopup(type) {
        const p = props.data.monitors.find(e => e.type === type);
        setEditData(p || { type: type });
        setShowEditPopup(true);
    }

    function orderService(monitor, cancel = false) {
        const mon = {
            id: monitor.id,
            orderInfo: monitor.orderInfo ? JSON.stringify(monitor.orderInfo): undefined,            
            status: cancel ? 0 : 1
        }

        setSendInProgress(true);

        LogitarApi.setServiceMonitor(mon, false, false)
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar(!cancel ? "Huolto tilattu" : "Huolto peruttu");
                    if (props.requestRefresh)
                        props.requestRefresh();
                }
                else {
                    enqueueSnackbar(!cancel ? "Huollon tilaus epäonnistui" : "Huollon peruminen epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(!cancel ? "Huollon tilaus epäonnistui" : "Huollon peruminen epäonnistui", { variant: 'error' });
            })
            .finally(() => {
                setSendInProgress(false);
            })
        setOrderMonitor(null);
    }

    function approveService(monitor, info) {
        const mon = {
            id: monitor.id,
            approveInfo: info
        }
        setSendInProgress(true);

        LogitarApi.setServiceMonitor(mon, true, false)
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar("Huolto kuitattu");
                    if (props.requestRefresh)
                        props.requestRefresh();

                    setApproveMonitor(null);
                }
                else {
                    enqueueSnackbar("Huollon kuittaus epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Huollon kuittaus epäonnistui", { variant: 'error' });
            })
            .finally(() => {
                setSendInProgress(false);
            });
    }

    const lastInspectionDate = new Date(props.data.vehicle.inspectionDate);
    const nextInspectionDate = new Date(props.data.vehicle.inspectionByDate);


    const kilometres = Number(props.data.vehicle.kilometres);

    const oilMonitor = props.data.monitors.find(e => e.type == ServiceMonitorType.OIL_CHANGE);
    const interimMonitor = props.data.monitors.find(e => e.type == ServiceMonitorType.INTERIM_SERVICE);
    const yearlyMonitor = props.data.monitors.find(e => e.type == ServiceMonitorType.YEARLY_SERVICE)

    // Day in ms
    const day2ms = 1000 * 3600 * 24;

    // Calculte days until inspection
    const timeUntilInspection = props.data.vehicle.inspectionByDate ? Math.floor((nextInspectionDate.getTime() - Date.now()) / day2ms) : null;

    const oilKmLeft = oilMonitor ? calculateNextService(
        kilometres,
        Number(oilMonitor.firstServiceKm),
        Number(oilMonitor.serviceIntervalKm),
        oilMonitor.previous ? Number(oilMonitor.previous.serviceDoneKm) : null
    )
        : null;

    const next_oil_rounded = oilMonitor ? (kilometres + oilKmLeft) : null;
    const last_oil_rounded = oilMonitor ? (kilometres + oilKmLeft) - Number(oilMonitor.serviceIntervalKm) : null;

    let interimKmLeft = interimMonitor ? calculateNextService(
        kilometres,
        Number(interimMonitor.firstServiceKm),
        Number(interimMonitor.serviceIntervalKm),
        interimMonitor.previous ? Number(interimMonitor.previous.serviceDoneKm) : null
    )
        : null;

    if ((kilometres + interimKmLeft) === next_oil_rounded || (kilometres + interimKmLeft) === last_oil_rounded) {
        interimKmLeft += Number(interimMonitor.serviceIntervalKm);
    }

    const regDate = props.data.vehicle.registrationDate ? (new Date(props.data.vehicle.registrationDate).getTime()) : 0;

    const yearlyMsLeft = yearlyMonitor ? calculateNextService(
        Date.now(),
        regDate + (day2ms * 365 * 3),
        day2ms * 365 * 3 /* 3 years */,
        yearlyMonitor.previous ? (new Date(yearlyMonitor.previous.done).getTime()) : null,
        true
    )
        : null;

    const monitors = {
        [ServiceMonitorType.OIL_CHANGE]: oilMonitor || 0,
        [ServiceMonitorType.INTERIM_SERVICE]: interimMonitor || 0,
        [ServiceMonitorType.YEARLY_SERVICE]: yearlyMonitor || 0,
    } 
    const monitorRemaining = {
        [ServiceMonitorType.OIL_CHANGE]: oilKmLeft || 0,
        [ServiceMonitorType.INTERIM_SERVICE]: interimKmLeft || 0,
        [ServiceMonitorType.YEARLY_SERVICE]: (yearlyMsLeft || 0) / day2ms,
    }

    // These two values must be found. A bit difficult since some services are time based and others km based.
    // Next service date
    let nextServiceDate = new Date("3000-1-1");
    let nextServiceType = { type: "-", unit: "" };
    // Last service date
    let lastServiceDate = new Date("1970-1-1");
    let lastServiceType = { type: "-", unit: "" };

    // Get previous inspection date
    if (props.data.vehicle.inspectionDate) {
        // Set inspection date
        lastServiceDate = new Date(props.data.vehicle.inspectionDate);
        lastServiceType = { type: "Katsastus", unit: "pv" };
    }
    // Get next inspection date
    if (props.data.vehicle.inspectionByDate) {
        // Set inspection date
        nextServiceDate = new Date(props.data.vehicle.inspectionByDate);
        nextServiceType = { type: "Katsastus", unit: "pv" };
    }
    props.data.monitors.forEach((e) => { 

        let nd = new Date("3000-1-1");
        let stype = "-";
        let sunit = "";
        // Set next dates
        switch (e.type) {
            case ServiceMonitorType.INTERIM_SERVICE:
            case ServiceMonitorType.OIL_CHANGE:
                // Estimate next service date based on driven km
                nd = new Date(Date.now() + (monitorRemaining[e.type] / averageKmPerDay * day2ms));
                stype = e.type == ServiceMonitorType.INTERIM_SERVICE ? "Väli" : "Öljy";
                sunit = "km";
                break;
            case ServiceMonitorType.YEARLY_SERVICE:
                // Get next 3v service date
                nd = new Date(Date.now() + (monitorRemaining[e.type] * day2ms));
                stype = "3v";
                sunit = "pv";
                break;
        }
        if (nd.getTime() < nextServiceDate.getTime()) {
            nextServiceDate = nd;
            nextServiceType = { type: stype, unit: sunit };
        }

        // Check for previous
        if (!e.previous)
            return;

        const d = new Date(e.previous.done);
        if (d.getTime() > lastServiceDate.getTime()) {
            lastServiceDate = d;
            lastServiceType = { type: stype, unit: sunit };
        }
    })

    const timeBetweenServices = nextServiceDate.getTime() - lastServiceDate.getTime();
    const timeUntilService = nextServiceDate.getTime() - Date.now();

    const serviceMult = clamp(0, 1, 1 - (timeUntilService / timeBetweenServices));

    /**
     * 
     * @param {{type: ServiceMonitorType, disableMonitorEdit?: boolean}} props 
     */
    const MonitorLabel = (props) => {
        const monitorTexts = {
            [ServiceMonitorType.OIL_CHANGE]: "öljynvaihto",
            [ServiceMonitorType.INTERIM_SERVICE]: "välihuolto",
            [ServiceMonitorType.YEARLY_SERVICE]: "3v huolto",
        } 

        if (!monitors[props.type]) {
            if (props.disableMonitorEdit) {
                return null;
            }
            return <Tooltip title={`Lisää ${monitorTexts[props.type]} seuranta`}>
                <IconButton
                    size="small"
                    sx={{ my: -4 }}
                    onClick={() => openEditPopup(props.type)}
                >
                    <Add />
                </IconButton>

            </Tooltip>
        }

        const isKmService = (props.type == ServiceMonitorType.OIL_CHANGE || props.type == ServiceMonitorType.INTERIM_SERVICE);

        let nextServiceText = "";
        if (isKmService) {
            nextServiceText = kilometres + monitorRemaining[props.type] + "km";
        }
        else {
            nextServiceText = format(new Date(Date.now() + monitorRemaining[props.type] * day2ms), "dd.MM.yyyy");
        }

        let remainingClass = "service-normal";
        if(monitors[props.type].status == 1) {
            remainingClass = "service-ordered";
        }
        else if((isKmService && monitorRemaining[props.type] < 0) || (!isKmService && monitorRemaining[props.type] < 0)) {
            remainingClass = "service-overdue";
        }
        else if((isKmService && monitorRemaining[props.type] < 10000) || (!isKmService && monitorRemaining[props.type] < 10 )) {
            remainingClass = "service-serviceSoon";
        }

        return <Box>
            <Tooltip
                title={`Seuraava ${monitorTexts[props.type]} @ ${nextServiceText}`}
            >
                <span
                    onClick={() => props.disableMonitorEdit ? null : openEditPopup(props.type)}
                    style={{ cursor: props.disableMonitorEdit ? undefined : 'pointer', padding: 4, borderRadius: 4 }}
                    className={remainingClass}
                >
                    {monitorRemaining[props.type].toFixed(0) + (isKmService ? "km" : "pv")}
                </span>
            </Tooltip>
            {
                sendInProgress &&
                <CircularProgress size={20} />
            }
            {
                (!sendInProgress && monitors[props.type].status == 0) &&
                <Tooltip title="Tilaa huolto">
                    <IconButton 
                        size="small" 
                        sx={{ my: -4 }} 
                        onClick={() => {
                            const e = monitors[props.type];
                            // Transform the type so the popup understands it...
                            setOrderMonitor({
                                id: e.id,
                                serviceAt: nextServiceText,
                                vehicle: vehicle.id + ": " + vehicle.licenseNumber,
                                km: kilometres || 0,
                                type: monitorTexts[e.type],
                                serviceType: e.type,
                                untilService: monitorRemaining[e.type],
                                status: e.status,
                                estDate: new Date(),
                                driver: e.driver,
                                vehicleId: vehicle.id
                            });
                        }}
                    >
                        <Assignment />
                    </IconButton>
                </Tooltip>
            }
            {
                (!sendInProgress && monitors[props.type].status == 1) &&
                <>
                    <Tooltip title="Kuittaa huolto">
                        <IconButton 
                            size="small" 
                            sx={{ my: -4 }} 
                            onClick={() => { 
                                const e = monitors[props.type];
                                setApproveMonitor({
                                    id: e.id,
                                    serviceAt: nextServiceText,
                                    vehicle: vehicle.id + ": " + vehicle.licenseNumber,
                                    km: kilometres || 0,
                                    type: monitorTexts[e.type],
                                    serviceType: e.type,
                                    untilService: monitorRemaining[e.type],
                                    status: e.status,
                                    estDate: new Date(),
                                    driver: e.driver,
                                    vehicleId: vehicle.id,
                                    orderInfo: e.orderInfo ? JSON.parse(e.orderInfo) : undefined,
                                    orderInfo2: e.orderInfo2 ? JSON.parse(e.orderInfo2) : undefined,
                                    vehicleType: vehicle.type                                   
                                })
                            }}
                        >
                            <AssignmentTurnedIn />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Peru huollon tilaus">
                        <IconButton size="small" sx={{ my: -4 }} 
                            onClick={() => {
                                const e = monitors[props.type];
                                setCancelOrderMonitor({
                                    id : e.id,
                                    orderInfo: e.orderInfo ? JSON.parse(e.orderInfo) : undefined
                                })
                            }}                             
                        > 
                        <   Cancel />
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Box>
    }

    return <Card sx={{ p: 1, mr: 2, mb: 2, height: 'auto', width: 270 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6">{props.data.vehicle.licenseNumber}</Typography>
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'flex-end' }} >
                {props.data.vehicle.type == 1 ? ((props.data.vehicle.kilometres || "0") + "km") : "Perävaunu"}
            </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Table>
            <TableBody
                sx={{
                    '& .MuiTableCell-root': {
                        px: 0.1
                    }
                }}
            >
                {
                    props.data.vehicle.type == 1 &&
                    <>
                        <TableRow>
                            <TableCell variant="head">Öljynvaihto</TableCell>
                            <TableCell>
                                {
                                    <MonitorLabel
                                        type={ServiceMonitorType.OIL_CHANGE}
                                        disableMonitorEdit={props.disableMonitorEdit}
                                    />
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Välihuolto</TableCell>
                            <TableCell>
                                {
                                    <MonitorLabel
                                        type={ServiceMonitorType.INTERIM_SERVICE}
                                        disableMonitorEdit={props.disableMonitorEdit}
                                    />
                                }
                            </TableCell>
                        </TableRow>
                    </>
                }
                {
                    props.data.vehicle.type == 2 &&
                    <TableRow>
                        <TableCell variant="head">3v huolto</TableCell>
                        <TableCell>
                            {
                                <MonitorLabel
                                    type={ServiceMonitorType.YEARLY_SERVICE}
                                    disableMonitorEdit={props.disableMonitorEdit}
                                />
                            }
                        </TableCell>
                    </TableRow>
                }
                <TableRow>
                    <TableCell variant="head">Katsastus</TableCell>
                    <TableCell>{
                        props.data.vehicle.inspectionByDate ?
                        <>
                            <Tooltip
                                title={`Seuraava katsastus @ ${format(nextInspectionDate, "dd.MM.yyyy")}`}
                            >
                                <span
                                    style={{ cursor: 'pointer', padding: 4, borderRadius: 4}}
                                    className={timeUntilInspection < 0 ? "service-overdue": timeUntilInspection < 10 ? "service-serviceSoon" : null}
                                >
                                    {(timeUntilInspection) + "pv"}
                                </span>
                            </Tooltip>
                            <Tooltip title="Tilaa katsastus">
                            <IconButton size="small" sx={{ my: -4 }} 
                                onClick={() => {
                                    setInspectionVehicle(props.data.vehicle.id)
                                }}                             
                            > 
                            <Assignment />
                            </IconButton>
                        </Tooltip>
                        </>
                            : "-"
                    }
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, width: '100%' }}>
            <Box sx={{ width: 48, display: 'flex', alignItems: 'center', mr: 1, flexDirection: 'column' }}>
                <Build sx={{ height: 18 }} />
                <span style={{ fontSize: 10 }}>{lastServiceType.type}</span>
            </Box>
            <Box sx={{ width: '100%', borderBottom: '1px solid #CCC', height: 36, position: 'relative' }}>
                <img src="./favicon.png" style={{ height: 32, position: 'absolute', left: (serviceMult * 75) + "%", filter: theme.palette.mode === "light" ? "none" : "invert(100%)" }} />
            </Box>
            <Box sx={{ width: 48, display: 'flex', alignItems: 'center', ml: 1, flexDirection: 'column' }}>
                <Build sx={{ height: 18 }} />
                <span style={{ fontSize: 10 }}>{nextServiceType.type}</span>
            </Box>
        </Box>
        {
            showEditPopup &&
            <ServiceMonitorPop
                data={editData}
                vehicle={props.data.vehicle}
                onClose={() => {
                    if (props.requestRefresh) {
                        props.requestRefresh();
                    }
                    setShowEditPopup(false);
                }}
                userList={LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ? [LogiTarUser.current.info] : undefined}
            />
        }
        {
            !!orderMonitor &&
            <ServiceMonitorOrderPop
                row={orderMonitor}
                onClose={() => setOrderMonitor(null)}
                onSave={(o) => orderService(o)}
            />
        }
        {
            !!approveMonitor &&
            <ServiceMonitorApprovePop
                row={approveMonitor}
                onClose={() => setApproveMonitor(null)}
                onApprove={(o, info) => approveService(o, info)}
            />
        }
        {
            inspectionVehicle !== null &&
            <InspectionPop
                vehicle={inspectionVehicle}
                onClose={() => { setInspectionVehicle(null); if (props.requestRefresh) props.requestRefresh(); }}
            />
        }
        {
            !!cancelOrderMonitor &&
            <AlertPop
                title="Haluatko varmasti poistaa huollon tilauksen?"
                onClose={(a) => {
                    if (a) {
                        orderService(cancelOrderMonitor, true);
                    }
                    setCancelOrderMonitor(null);
                }}
                cancellable
            />
        }
    </Card>
}
