import { Box, Divider, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import DriverReportCard from "../components/driverreports/DriverReportCard.tsx";
import { contentBoxStyle } from "../styles/styles";

/**
 * @typedef {{data: import("../api/LogitarApiTypes").DriverReportRow}} DriverReportCardProps
 */

const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);
const endDate = new Date();

const headerHeight = 73;

export default function DriverReports() {

    const [dateRange, setDateRange] = useState([startDate, endDate]);

    const [data, setData] = useState(/** @type {import("../api/LogitarApiTypes").DriverReportRow[]} */([]));

    const theme = useTheme();
    const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {

        LogitarApi.getDriverReports({
            from: dateRange[0],
            to: dateRange[1]
        })
            .then((resp) => {
                setData(resp.drivers);
            })
            .catch((err) => {

            })

    }, [dateRange]);

    return <Box
        sx={{
            height: '100%',
            ...contentBoxStyle,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <Box sx={{ maxWidth: 500, px: 1 }}>
            <DateRangeSelect
                value={dateRange}
                onChange={(val) => {
                    setDateRange(val);
                }}
            />
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', height: `calc(100% - ${headerHeight}px)`, flexDirection: lgBreakPoint ? 'row' : 'column-reverse', overflowX: 'auto' }}>
            <Box sx={{ flex: 1, overflowY: 'auto', px: 1 }}>
                {
                    data.map((e, i) => {
                        return <DriverReportCard data={e} key={"rep-" + i} />
                    })
                }
            </Box>
            <Box sx={{ maxWidth: lgBreakPoint ? 300 : "100%", minWidth: 200, overflow: 'auto', px: 1, mb: lgBreakPoint ? undefined : 1 }}>
                <Tabs
                    orientation={lgBreakPoint ? "vertical" : "horizontal"}
                    visibleScrollbar
                    sx={{ "& .MuiTabs-flexContainer": { flexWrap: "wrap" }}}
                >
                    {
                        data.map((e, i) => {
                            return <Tab
                                key={"tab-" + i}
                                label={e.driver.name}
                                onClick={() => {
                                    window.location.hash = "card-" + e.driver.id;
                                }}
                            />
                        })
                    }
                </Tabs>
            </Box>
        </Box>
    </Box>
}