import Login from './views/Login.js'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogTitle, Fade, Slide, Snackbar, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react'
import HeaderBar from './components/HeaderBar';
import SideBar from './components/SideBar';
import LogitarApi from './api/LogitarApi';
import { APIPath } from './api/LogitarApi';
import getRoutes from './misc/Routes/Routes.js';
import { getErrorLog } from './api/Errors';
import LogiTarUser, { LogiTarUserType } from './misc/User';
import UserEdit from './components/UserEdit';
import './styles/animation.css'
import { logitarTheme, logitarThemeMode, setThemeMode } from './config/Themes.js';
import { drawerWidth, drawerWidthCollapsed, headerBarHeight } from './styles/styles.js';
import { SnackbarProvider } from 'notistack';
import LogitarEvent from './api/LogitarEvent.js';
import ServerStatus from './views/ServerStatus.tsx';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorScreen from './misc/ErrorScreen.tsx';
import ErrorStackParser from 'error-stack-parser';
import LogitarEventProvider from './api/LogitarEventProvider.js';
import { APIProvider } from '@vis.gl/react-google-maps';
import { googleApiKey } from './misc/apigoogle.js';
import VehicleAlertNotifier from './components/VehicleAlertNotifier.tsx';

const emptyMainStyle = {
	mt: 0,
	display: "flex",
	flexDirection: "column",
	height: '100vh',
	alignItems: 'center',
	justifyContent: 'center'
}

export let sidebarIsOpen = true;

/**
 * @type {{id: number, cb: (open: boolean) => void}[]}
 */
const sidebarChangeListeners = [];

let __sidebarListenerId = 1;

export const addSidebarChangeListener = (cb) => {
	const nid = __sidebarListenerId++;
	sidebarChangeListeners.push({ cb: cb, id: nid });
	return nid;
}
export const removeSidebarChangeListener = (id) => {
	const ix = sidebarChangeListeners.findIndex(e => e.id === id);
	if (ix >= 0) {
		sidebarChangeListeners.splice(ix, 1);
	}
}

function App() {

	//Variants: default | error | success | warning | info
	//const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [login, setLogin] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [header, setHeader] = React.useState()
	const [showProfile, setShowProfile] = React.useState(false)
	const [sidebarExpanded, setSidebarExpanded] = useState(true);

	const [appThemeMode, setAppThemeMode] = useState(logitarThemeMode);
	const [appTheme, setAppTheme] = useState(logitarTheme);

	const theme = useTheme();
	const smBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

	const [tokenExpired, setTokenExpired] = useState(false);

	/**
	 * @brief Check if users login is valid
	 */

	const authenticateKey = () => {

		let cookieFormatted = LogitarApi.getCookie("login-token")

		if (cookieFormatted) {
			let response = fetch(`${APIPath}/login.php?key=${cookieFormatted}`)
				.then(response => response.json())
				.then(result => {
					if (result.status) {

						const user = LogiTarUser.current ? LogiTarUser.current : new LogiTarUser(result.token, result.user)
						LogiTarUser.current = user

						console.log("Connect to event system");
						// Login to event system
						LogitarEvent.connect(user.token.event_url);

						setLogin(true)
						if (!header) findHeader(user)
					} else {
						// LogiTarUser.current = null // Nulling this seems to cause errors elsewhere
						// setLogin(false) // Not necessary, since the tokenExpired dialog forces user to refresh
						setTokenExpired(true);
						console.log(`Error code: ${getErrorLog(result.error)}`)
					}
					setLoading(false)
				})
				.catch(err => { console.error(err) })
		} else {
			setLogin(false)
			setLoading(false)
		}

	}

	const handleFrontendError = async (error) => {
		try {
			const parsedStack = ErrorStackParser.parse(error);
			// Log first 2 levels of the error stack
			for (let i = 0; i < 2; i++) {
				const errorFilePath = parsedStack[i].fileName;
				const sourceMapFetch = await fetch(`${errorFilePath}.map`);
				const sourceMapObj = await sourceMapFetch.json();
				const errorPos = { line: parsedStack[i].lineNumber, column: parsedStack[i].columnNumber };
				const result = await window.sourceMap.SourceMapConsumer.with(sourceMapObj, null, consumer => {
					return consumer.originalPositionFor(errorPos);
				});
				const errorObj = {
					description: `${i + 1}/2 ${error.name}: ${error.message}${result.name ? ` at ${result.name}` : ""} (${result.source}:${result.line}:${result.column})`,
					file: result.source,
					line: result.line,
					column: result.column,
				};
				LogitarApi.frontendError(errorObj);
			}
		} catch (error) {
			console.error("Logging frontend error failed! Error below.");
			console.error(error);
		}
	}

	/**
	 * @brief Find header for the given user
	 * @param {LogiTarUser} user 
	 * @returns String header, defaults to "Tervetuloa" if not found
	 */
	const findHeader = (user) => {

		console.log("Finding header...")

		const routes = Object.values(getRoutes(user.info.userType))

		let route = "Tervetuloa"
		for (let i = 0; i < routes.length; i++) {
			const routeObj = routes[i].find((page) => page.path === window.location.pathname)

			if (routeObj) {
				route = routeObj.header
				break
			}
		}

		setHeader(route)
	}

	useEffect(() => {
		setAppTheme({ ...logitarTheme });
	}, [appThemeMode])

	//Call authentication on refresh and navi
	useEffect(() => {
		// Unsubscribe from all events on change view
		authenticateKey()
	}, [header])

	useEffect(() => {
		sidebarIsOpen = sidebarExpanded;
		sidebarChangeListeners.forEach((e) => {
			e.cb(sidebarIsOpen);
		})
	}, [sidebarExpanded]);

	useEffect(() => {
		setSidebarExpanded(!smBreakPoint);
	}, [smBreakPoint])


	//Display loading screen before authenticated
	if (loading) {
		return (
			<Box className="App-main-box" sx={emptyMainStyle}>
				<CircularProgress></CircularProgress>
			</Box>
		)
	}

	//Display login screen if not authenticated
	if (!login) {
		return (
			<Box className="App-main-box"
				sx={emptyMainStyle}
			>
				<ThemeProvider theme={appTheme}>
					<CssBaseline />
					<SnackbarProvider
						maxSnack={3}
						autoHideDuration={5000}
						anchorOrigin={{ horizontal: "center", vertical: "bottom" }}

					>
						<Login />
					</SnackbarProvider>
				</ThemeProvider>
			</Box >
		)
	}

	const RouteItems = getRoutes(LogiTarUser.current.info.userType)

	return (
		<div className="App" style={{
			height: `calc(100vh - ${headerBarHeight}px)`,
			marginTop: `${headerBarHeight}px`
		}}>
			<link
				rel="stylesheet"
				href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
			/>

			<ThemeProvider theme={appTheme}>
				<CssBaseline />
				<SnackbarProvider
					maxSnack={3}
					autoHideDuration={5000}
					anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
					variant='success'
				>
					<APIProvider apiKey={googleApiKey}>
						<Router>
							<Box
								sx={{ display: 'flex', overflowX: 'hidden' }}
							>
								<HeaderBar
									header={header}
									onProfileOpen={() => setShowProfile(true)}
									onExpand={() => setSidebarExpanded(true)}
									sidebarExpanded={sidebarExpanded}
									onChangeThemeMode={() => {
										if (appThemeMode === 'light') {
											setAppThemeMode("dark");
											setThemeMode("dark");
										}
										else {
											setAppThemeMode("light");
											setThemeMode("light");
										}
									}}
								/>
								<SideBar
									onClick={(header) => {
										setHeader(header);
										if (smBreakPoint) setSidebarExpanded(false);
									}}
									routes={RouteItems}
									open={sidebarExpanded}
									onCollapse={() => setSidebarExpanded(false)}
								/>
								<Box
									sx={{
										width: sidebarExpanded ? `calc(100vw - ${drawerWidth}px)` : '100%',
										ml: sidebarExpanded ? `${drawerWidth}px` : 0,
										height: `calc(100vh - ${headerBarHeight}px)`
									}}
								>
									<ErrorBoundary FallbackComponent={ErrorScreen} onError={(e) => handleFrontendError(e)}>
										{/** Routes mapped with routes.js objects */}
										<Routes>
											{Object.keys(RouteItems).map((headerName, index) => (
												RouteItems[headerName].map(({ header, component, path }, routeIndex) => (
													<Route key={header} path={path} element={component} />
												))
											))}
											{/** TODO: Move to admin routes, when admin is specific for softrain */}
											<Route path='/serverstatus' key="Server Status" element={<ServerStatus />} />
										</Routes>
									</ErrorBoundary>
								</Box>
								{
									showProfile &&
									<UserEdit profileMode user={LogiTarUser.current.info} onClick={() => setShowProfile(false)} /*new={createNew} count={count} rowUpdate={setCount}*/ />
								}
								<LogitarEventProvider
									subscriptions={["token"]}
									onEvent={(source, data, id) => {
										if (data.expired) setTokenExpired(true);
									}}
								/>
								<Dialog open={tokenExpired}>
									<DialogTitle>Kirjautumisesi on vanhentunut</DialogTitle>
									<DialogActions>
										<Button variant='contained' onClick={() => LogitarApi.logout()}>Kirjaudu uudelleen</Button>
									</DialogActions>
								</Dialog>
								{
									/* TODO: disabled until user can filter alerts
									<VehicleAlertNotifier />
									*/
								}
							</Box>
						</Router>
					</APIProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</div>
	);
}

export default App;
