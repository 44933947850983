import { Box } from "@mui/system";
import { logoPaths } from "../PlanningCarRow";
import Config from "../../config/Config";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { thousandsSeparator } from "../../misc/Formatters";


/**
 * @typedef {{
 *  range: {
 *      start: string,
 *      end: string
 *  },
 *  clientName: string
 * }} EmissionReportData
 */

/**
 * @typedef {{
 *  data: EmissionReportData
 * }} EmissionsOverviewProps
 */

/**
 * 
 * @param {EmissionsOverviewProps} props 
 * @returns 
 */
export default function EmissionsOverview(props) {

    const reportInfo = props.data;

    return <Box
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 80
            }}
        >
            <Box sx={{ flex: 1, display: 'flex', padding: 2.5 }}>
                <img src={logoPaths[Config.getBranch()].path} style={{ height: '100%' }} />

            </Box>
            <Typography sx={{ flex: 1, textAlign: 'center', p: 2, color: 'white' }} variant="h4">
                Päästöraportti
            </Typography>
            <Typography
                sx={{
                    flex: 1,
                    verticalAlign: 'top',
                    textAlign: 'end',
                    p: 1,
                    color: 'white'
                }}
            >
                {format(new Date(reportInfo.range.from), "dd.MM.yyyy")} - {format(new Date(reportInfo.range.to), "dd.MM.yyyy")}
            </Typography>
        </Box>
        <Typography
            variant="h4"
            sx={{ color: 'white', p: 3 }}
        >
            {props.data.clientName}
        </Typography>
        <Box sx={{
            flex: 1, color: 'white',
            display: 'flex', flexDirection: 'column',
            "& .Cell": {
                textAlign: 'center'
            },
            "& .CellHead": {
                fontSize: '32pt'
            },
            "& .CellValue": {
                fontSize: '18pt'
            }
        }}>
            <table style={{ tableLayout: 'fixed', width: '100%', flex: 1 }}>
                <tbody>
                    <tr>
                        <td className="Cell">
                            <Typography className="CellHead">Km</Typography>
                            <Typography className="CellValue">{thousandsSeparator(reportInfo.totalKm.toFixed(0))}<span style={{color:'#CCC'}}>km</span></Typography>
                        </td>
                        <td className="Cell">
                            <Typography className="CellHead">Tonnit</Typography>
                            <Typography className="CellValue">{thousandsSeparator((reportInfo.totalTons).toFixed(0))}<span style={{color:'#CCC'}}>t</span></Typography>

                        </td>
                        <td className="Cell">
                            <Typography className="CellHead">CO<sub>2</sub></Typography>
                            <Typography className="CellValue">{(reportInfo.totalCO2 / 1000).toFixed(1)}<span style={{color:'#CCC'}}>t</span></Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width: '100%', flex: 1 }}>
                <tbody>
                    <tr>
                        <td className="Cell">
                            <Typography className="CellHead">CO<sub>2</sub>/Km</Typography>
                            <Typography className="CellValue">{(reportInfo.CO2PerKm * 1000).toFixed(0)}<span style={{color:'#CCC'}}>g</span></Typography>
                        </td>
                        <td className="Cell">
                            <Typography className="CellHead">CO<sub>2</sub>/Tonnit</Typography>
                            <Typography className="CellValue">{(reportInfo.CO2PerTons * 1000).toFixed(0)}<span style={{color:'#CCC'}}>g</span></Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
            <img src={"./bg/emission-report-overlay.png"} style={{ width: '100%' }} />
        </Box>

    </Box>
}