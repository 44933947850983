import { Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

/**
 * @typedef {{
 *  jobs: object[],
 *  open?: boolean,
 *  onClose?: () => void,
 *  deleted?: boolean,
 *  future?: boolean,
 * }} JobChangePopupProps
 */

/**
 * 
 * @param {JobChangePopupProps} props 
 * @returns 
 */
export default function JobChangePopup(props) {

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        let njobs = [];
        props.jobs.forEach((e) => {
            const njrow = njobs.find(y => format(y.date, "yyyy-MM-dd") === e.date && y.shift === e.shift);
            if (njrow) {
                njrow.jobs.push(e);
            }
            else {
                njobs.push({
                    date: new Date(e.date),
                    shift: e.shift,
                    jobs: [e]
                })
            }
        })

        setJobs(njobs);

        console.log(njobs);
    }, [props.jobs])
    
    const getShiftName = (shift) => {
        if (shift === "A") return "Aamu";
        if (shift === "I") return "Ilta";
        return "";
    }

    const getTitle = () => {
        if (!props.future || !jobs.length) return "Ajo-ohjelmasi on muuttunut!";
        let title = "Ajo-ohjelma muuttunut vuorolle:";
        title += " " + format(jobs[0].date, "dd.MM.yyyy");
        title += " " + getShiftName(jobs[0].shift);
        return title;
    }

    return <Dialog
        open={props.open}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                if (props.onClose) props.onClose();
            }
        }}
    >
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: props.future ? "bold" : undefined}}>Pvm</TableCell>
                        <TableCell>Vuoro</TableCell>
                        <TableCell>Tiedot</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        jobs.map((e, i) => {
                            return e.jobs.map((ej, ij) => {
                                return <TableRow key={i + " " + ij}>
                                    <TableCell sx={{fontWeight: props.future ? "bold" : undefined}}>
                                        {ij === 0 ? format(e.date, "dd.MM.yyyy") : ""}
                                    </TableCell>
                                    <TableCell>{ij === 0 ? getShiftName(e.shift) : ""}</TableCell>
                                    <TableCell>
                                        {
                                            props.deleted ? 
                                            <span><s>{ej.item.name} - {ej.item.cargoLabel}</s><i>(Poistettu)</i></span> : 
                                            <span>{ej.item.name} - {ej.item.cargoLabel}</span>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            })
                        })
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { if (props.onClose) props.onClose() }} variant="contained">OK</Button>
        </DialogActions>
    </Dialog>
}