import { AltRoute, Delete } from "@mui/icons-material";
import { Box, Button, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import DragBox from "../components/DragBox";
import EditableTimeline, { EditableTimelinePopup } from "../components/EditableTimeline";
import PlanningNotes from "../components/PlanningNotes";
import { logitarTheme } from "../config/Themes";
import { Loader } from "../misc/InternalFeatures";
import { pSBC } from "../misc/pSBC";
import { contentBoxStyle } from "../styles/styles";
import { PLANNING_POLL_RATE } from "./Planning";
import { CreateGroupedOrderList } from "./PlanningOrderList.tsx";
import { vehicleGroupSorter } from "./Vehicles";

/**
 * @brief Transforms an UTC timestamp to local time
 * @param {number} timestamp Timestamp in milliseconds
 * @returns 
 */
const timestampUTCToLocal = (timestamp) => {
    // Create date object - get timezone offset from it
    const d = new Date();
    return timestamp + (d.getTimezoneOffset() * 60 * 1000);
}

/**
 * @brief Transforms a local timestamp to UTC
 * @param {number} timestamp Timestamp in milliseconds
 * @returns 
 */
const timestampLocalToUTC = (timestamp) => {
    // Create date object - get timezone offset from it
    const d = new Date();
    return timestamp - d.getTimezoneOffset() * 60 * 1000;
}

/**
 * @brief Convert shift hook value to actual morning or evening shift "signature". 'I' for Evening, 'A' for morning
 * @param {boolean|number} nshift 
 * @returns 
 */
const convertShift = (nshift) => nshift ? "I" : "A";

const zeroPad = (num, places) => String(num).padStart(places, '0');

const dayButtonStyle = {
    fontSize: 26,
    height: "1.2em"
};

//Plan coloring
const fieldClasses = [
    //Created / not sent, red
    "default-red",
    //Sent job, yellow
    "default-yellow",
    //Job seen, green
    "default-green",
    //Job loaded, blue
    "default-blue",
    //Job unloaded, white
    undefined
]
/**
 * @brief Timeout for sending the plans
 */
let sendJobsTimer = null;

export default function PlanningTimeline() {

    // Loading indicator
    const [loading, setLoading] = useState(false);
    // Used to force refresh
    const [count, setCount] = useState(0);

    const [vehicles, setVehicles] = useState(/** @type {GetPlanningRow[]} */([]));
    const vehiclesRef = useRef(vehicles);
    const [timelineData, setTimelineData] = useState(/** @type {import("../components/EditableTimeline").EditableTimelineData} */([]));
    const [date, setDate] = useState(new Date());

    const [dirtyJobs, setDirtyJobs] = useState(/** @type {{[key: number]: object}} */{})
    const dirtyJobsRef = useRef(dirtyJobs);

    const [shift, setShift] = useState(false)

    // Container of timeline
    const timelineContainer = useRef();
    const [timelineSize, setTimelineSize] = useState([0, 0]);

    // Timeline tooltip hover position
    const [hoverPos, setHoverPos] = useState([-1, -1]);
    // Timeline tooltip text
    const [hoverText, setHoverText] = useState("");
    // Timeline ctx element
    const [ctxElement, setCtxElement] = useState(null);
    const [ctxBar, setCtxBar] = useState(/** @type {[number, number]|null} */(null));
    // Timeline origin
    const [tlOrigin, setTlOrigin] = useState(Date.now() / 1000);

    const [orders, setOrders] = useState([])
    const [clientsItems, setClientsItems] = useState([])
    const [clientsList, setClientsList] = useState([])
    const [client, setClient] = useState(false)
    const [items, setItems] = useState([])

    // Orders/clients menu selection
    const [rightMenu, setRightMenu] = useState(/** @type {'orders'|'clients'} */('orders'));

    // Drag item data 
    const [dragItemData, setDragItemData] = useState(null);
    // Dragging refs and functions
    const dragItem = useRef();



    useEffect(() => {
        const startDate = new Date(date);
        const endDate = new Date(date);
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() + 1);

        // Fetch jobs, orders
        LogitarApi.getPlanningRange(startDate, endDate).then((result) => {
            if (!result)
                return;

            // Append plans
            appendVehicles(vehicleGroupSorter(result.vehicles, 'planning'), startDate, endDate);
        }).catch((err) => console.error(err));

        LogitarApi.getOrders({ date: format(date, "yyyy-MM-dd") }).then((result) => {

            setOrders(CreateGroupedOrderList(result.orders))
            setLoading(false)
        })
            .catch((err) => console.error(err))
    }, [date, count]);

    useEffect(() => {
        vehiclesRef.current = vehicles;
        // Build timeline data to a better format
        buildTimelineData(vehicles);
    }, [vehicles])

    const onWindowResize = () => {
        const b = timelineContainer.current.getBoundingClientRect();
        setTimelineSize([b.width, b.height]);
    }

    useEffect(() => {
        dirtyJobsRef.current = dirtyJobs;
    }, [dirtyJobs]);

    useEffect(() => {
        onWindowResize();
    }, [timelineContainer])

    useEffect(() => {
        const _interval = setInterval(() => {
            // Update count to forceupdate plans
            setCount(count + 1);
        }, PLANNING_POLL_RATE);

        //Get client, item only once
        LogitarApi.getClients().then((result) => {

            setClientsList(result.clients)

        })
            .catch((err) => console.error(err))

        LogitarApi.getItems().then((result) => {
            setItems(result.items)
        })
            .catch((err) => console.error(err))

        window.addEventListener("resize", onWindowResize);

        return () => {
            clearInterval(_interval);
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);

    /**
     * 
     * @param {GetPlanningRow[]} _vehicles 
     */
    const sortJobs = (_vehicles) => {
        const newVehicles = [..._vehicles];

        for (let c of newVehicles) {
            // Sort jobs by date, shift, slot
            c.jobs.sort((a, b) => {
                const d1 = new Date(a.date).getTime();
                const d2 = new Date(b.date).getTime();
                const s1 = a.shift === 'A' ? 0 : 1;
                const s2 = b.shift === 'A' ? 0 : 1;

                return d1 !== d2 ? (d1 > d2 ? 1 : -1) :
                    s1 !== s2 ? (s1 > s2 ? 1 : -1) :
                        a.slot !== b.slot ? (a.slot > b.slot ? 1 : -1) :
                            0;
            })
        }

        return newVehicles;
    }

    /**
     * @brief Appends new vehicles
     * @param {GetPlanningRow[]} newJobs 
     * @param {Date} startDate 
     * @param {Date} endDate 
     */
    const appendVehicles = (newJobs, startDate, endDate) => {
        let newVehicles = [...vehicles];

        // Push non existing cars
        newVehicles.push(...newJobs.filter(e => newVehicles.findIndex(x => x.vehicle.id === e.vehicle.id) < 0));

        // Filter jobs out of view
        newVehicles = newVehicles.map(ex => {
            return {
                ...ex,
                jobs: ex.jobs.filter((e) => {
                    const d = new Date(e.date);
                    return d.getTime() >= startDate.getTime() && d.getTime() <= endDate.getTime();
                })
            }
        })

        for (let c of newVehicles) {
            /**
             * @type {GetPlanningRow}
             */
            const np = newJobs.find(e => c.vehicle.id === e.vehicle.id);
            if (!np)
                continue;

            // Push, or update jobs if already exists
            for (let i = 0; i < np.jobs.length; i++) {
                const cp = c.jobs.findIndex(e => e.id === np.jobs[i].id);
                if (cp >= 0) {
                    c.jobs[cp] = { ...np.jobs[i] };
                }
                else {
                    c.jobs.push({ ...np.jobs[i] });
                }
            }
        }
        setVehicles(sortJobs(newVehicles));
    }

    /**
     * @brief Changes date by days
     * @param {number} days 
     */
    const changeDate = (days) => {
        const d = new Date(date);
        d.setDate(d.getDate() + days);
        setTlOrigin(d.getTime() / 1000);
        setDate(d);
    }

    /**
     * @brief Sends jobs
     * @param {GetPlanningRow} row
     * @returns 
     */
    const sendJobs = (row) => {
        // Update jobs
        const jobs = [];

        row.jobs.forEach((value, key) => {
            if (value.state < 1)
                jobs.push({ id: value.id, state: 1 });
        })

        if (jobs.length < 1) {
            return;
        }

        LogitarApi.setJobs(jobs).then((result) => {
            console.log("Jobs created")
            setCount(count + 1)
        })
            .catch((err) => console.error(err))
    }

    /**
     * @brief Sends a new job
     * @param {{id: number, itemNumber: string, client: number, name: string}} item 
     * @param {{vehicle: number, date: string, shift: boolean, slot: number}} placement
     */
    const sendNewJob = (item, placement) => {
        let job = {
            date: placement.date, shift: convertShift(placement.shift),
            item: item.type === 'order' ? item.item : item.id, state: 0, vehicle: placement.vehicle,
            slot: placement.slot
        }
        LogitarApi.setJobs([job]).then((result) => {

        })
        .catch((err) => console.error(err))
        .finally(() => {
            setCount(count + 1);
        })
    }

    /**
     * @brief Deletes a job
     */
    const deleteJob = () => {
        const newVehicles = [...vehicles];
        LogitarApi.setJobs({...newVehicles[ctxBar[0]].jobs[ctxBar[1]], ">deleted": true}).then((result) => {

            newVehicles[ctxBar[0]].jobs.splice(ctxBar[1], 1);
            setVehicles(newVehicles);
        })
            .catch((err) => console.error(err))
    }

    /**
     * @brief Transforms data to suitable format for EditableTimeline
     * @param {GetPlanningRow[]} data 
     */
    const buildTimelineData = (data) => {
        /**
         * @type {import("../components/EditableTimeline").EditableTimelineData}
         */
        const d = data.map((e, i) => {
            let ldate = null;
            let lshift = null;
            let offset = 0;
            let hasUnsentPlans = false;
            return {
                title: e.vehicle.id + " - " + e.vehicle.licenseNumber,
                subtitle: e.vehicle.currentUser ? `${e.vehicle.currentUser}:${e.vehicle.currentUserName}` : "",
                data: e.jobs.map((p) => {
                    if (ldate !== p.date || lshift !== p.shift) {
                        ldate = p.date;
                        lshift = p.shift;
                        offset = timestampUTCToLocal(new Date(p.date).getTime() + (3600 * 3 * 1000)) / 1000 + (p.shift === 'A' ? 0 : 3600 * 12);
                    }

                    const start = p.estStart ? timestampUTCToLocal(new Date(p.estStart).getTime()) / 1000 :
                        offset;
                    const size = (p.estDuration ? p.estDuration : p.item.hours) * 3600;

                    offset = start + size;
                    if (!hasUnsentPlans) {
                        hasUnsentPlans = p.state == 0;
                    }

                    return {
                        text: p.item.name,
                        subtext: zeroPad(Math.floor(size / 3600), 2) + ":" + zeroPad(Math.floor(size % 60), 2),
                        start: start,
                        size: size,
                        background: fieldClasses[p.state],
                        cursor: p.state >= 4 ? "not-allowed" : undefined
                    }
                }),
                onRowClick: hasUnsentPlans ? (row) => {
                    sendJobs(e);
                } : undefined
            }
        })

        setTimelineData(d);
    }

    // Updates the date/shift when panning on the timeline 
    function updateDateTimeOnPan(time) {
        const ndate = new Date(time * 1000);
        if (ndate.getDate() != date.getDate() || ndate.getMonth() != date.getMonth() || ndate.getFullYear() != date.getFullYear()) {
            setTlOrigin(ndate.getTime() / 1000)
            setDate(ndate);
        }
 
        setShift(ndate.getHours() < 12 ? true : false)
    }

    /**
     * 
     * @param {GetPlanningRow} vehicle
     * @param {string} _date 
     * @param {'A'|'I'} _shift 
     */
    const updateSlots = (vehicle, _date, _shift) => {
        const pl = vehicle.jobs.filter((e) => {
            return e.date === _date && e.shift === _shift;
        })
        let i = 0;
        for (let idx = 0; idx < pl.length; idx++) {
            pl[idx].slot = i++;
        }
    }

    /**
     * @brief Adds "dirty" job (a job that has been moved)
     * @param {object} job 
     * @param {object} vehicle
     */
    const addDirtyJob = (job, vehicle) => {
        const njobs = { ...dirtyJobsRef.current };

        njobs[job.id] = { ...job, vehicle: vehicle.id };

        setDirtyJobs(njobs);
        // Can call this often, will only send after 5sec of inactivity
        sendDirtyJobs();
    }

    const sendDirtyJobs = () => {
        if (sendJobsTimer !== null) {
            clearTimeout(sendJobsTimer);
        }
        // Send jobs after 5 seconds. Prevents spamming the API when editing on the timeline
        sendJobsTimer = setTimeout(() => {

            const jobs = Object.keys(dirtyJobsRef.current).map(k => {

                const p = dirtyJobsRef.current[Number(k)];
                return {
                    id: p.id, item: p.item.id, vehicle: p.vehicle, slot: p.slot, date: p.date,
                    shift: p.shift, state: p.state, details: p.details,
                    estStart: p.estStart, estDuration: p.estDuration
                }
            })

            LogitarApi.setJobs(jobs).then((result) => {
                setCount(count + 1);
            })
            .catch((err) => console.error(err))

            // Reset unsent jobs
            setDirtyJobs({});
            console.log("Jobs updated");
        }, 5000);
    }

    // Executed when a drag event is triggered on the timeline
    const onTimelineDrag = (e) => {
        if (e.type === 'pan') {
            // Pan view
            updateDateTimeOnPan(e.delta.x);
            setTlOrigin(e.delta.x);
        }
        else {
            // Drag job across time
            const newVehicles = [...vehiclesRef.current];
            const newStart = format(new Date(timestampLocalToUTC(e.newStart * 1000)), "yyyy-MM-dd HH:mm:ss");
            const newSize = e.newSize / 3600;

            if (newVehicles[e.pos[0]].jobs[e.pos[1]].state >= 4)
                return;

            if (newVehicles[e.pos[0]].jobs[e.pos[1]].estStart !== newStart || newVehicles[e.pos[0]].jobs[e.pos[1]].estDuration !== newSize) {

                const ndate = new Date(e.newStart * 1000);
                const nshift = ndate.getHours() >= 15;
                // Has date or shift changed, to reorder plans if so
                const shiftChanged = newVehicles[e.pos[0]].jobs[e.pos[1]].date !== format(ndate, "yyyy-MM-dd") ||
                    newVehicles[e.pos[0]].jobs[e.pos[1]].shift !== convertShift(nshift);

                newVehicles[e.pos[0]].jobs[e.pos[1]].estStart = newStart;
                newVehicles[e.pos[0]].jobs[e.pos[1]].estDuration = newSize;

                newVehicles[e.pos[0]].jobs[e.pos[1]].date = format(ndate, "yyyy-MM-dd");
                newVehicles[e.pos[0]].jobs[e.pos[1]].shift = convertShift(nshift);
                if (shiftChanged) {
                    console.log("Shift has been changed");
                    updateSlots(newVehicles[e.pos[0]], format(ndate, "yyyy-MM-dd"), convertShift(nshift));
                    newVehicles[e.pos[0]].jobs.map(d => {
                        addDirtyJob(d, newVehicles[e.pos[0]].vehicle);
                    })
                }
                else {
                    addDirtyJob(newVehicles[e.pos[0]].jobs[e.pos[1]], newVehicles[e.pos[0]].vehicle);
                }

                setVehicles(newVehicles);
            }
        }
    }

    // Called when client selected from client select
    const clientSelectionChange = (event) => {

        setClient(event.target.value)

        if (!event.target.value) { setClientsItems(); return }

        setClientsItems(() => items.filter((item) => event.target.value === item.client))
    }

    const dragStart = (e, order = null) => {
        dragItem.current = { el: e.target, order: order }
    }

    return <Box sx={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', height: '100%', ...contentBoxStyle }}> {/**row*/}
        <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: "no-wrap", height: "auto", flex: 5, mr: 1 }}> {/**column */}
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ display: "flex", flex: 3, flexDirection: "column" }}>{/**col */}
                    <Box sx={{ ml: 1, flex: 5, height: "auto", pt: 1, borderRadius: 2 }}>
                        <PlanningNotes date={date} shift={shift}/>
                    </Box>
                </Box>
                <Box sx={{ mx: 1, height: "45px", p: 1, textAlign: "center", borderRadius: 2,
                            display: "flex", flexDirection: "column", justifyContent: "space-between", mb: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", mt: 0.5 }}>
                        <Button sx={dayButtonStyle} onClick={() => changeDate(-1)}>{"<"}</Button>
                        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat='dd.MM.yyyy'
                                    mask={"__.__.____"}
                                    value={date}
                                    onChange={(value) => { setDate(value) }}
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            p: 0.5
                                    }}}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                ></DesktopDatePicker>
                            </LocalizationProvider>
                        <Button sx={dayButtonStyle} onClick={() => changeDate(1)}>{">"}</Button>
                    </Box>
                </Box>
            </Box>

            <Box ref={timelineContainer} sx={{ flex: 1, mt: 1, ml: 1, overflow: 'hidden' }}>
                {
                    hoverPos[0] >= 0 &&
                    <EditableTimelinePopup
                        x={hoverPos[0]}
                        y={hoverPos[1]}
                    >
                        <Typography>{hoverText}</Typography>
                    </EditableTimelinePopup>
                }
                <Menu
                    open={ctxElement != null}
                    onClose={() => { setCtxElement(null); setCtxBar(null) }}
                    anchorEl={ctxElement}
                >
                    {
                        ctxElement != null &&
                        <MenuItem>
                            <ListItemIcon>
                                <AltRoute fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Jaa</ListItemText>

                        </MenuItem>
                    }
                    {
                        ctxElement != null &&
                        <MenuItem
                            disabled={(ctxBar != null) && vehicles[ctxBar[0]].jobs[ctxBar[1]].state >= 1}
                            onClick={() => { setCtxElement(null); deleteJob(); }}
                        >
                            <ListItemIcon>
                                <Delete sx={{ color: '#e0453a' }} fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={{ color: '#e0453a' }}>Poista</ListItemText>
                        </MenuItem>
                    }
                </Menu>

                {(timelineSize[0] > 0 && timelineSize[1] > 0) &&
                    <EditableTimeline
                        width={timelineContainer.current.getBoundingClientRect().width}
                        height={timelineContainer.current.getBoundingClientRect().height}
                        data={timelineData}
                        origin={tlOrigin}
                        cursor={Date.now() / 1000}
                        scale={3600 * 24}
                        headerStep={3600}
                        step={3600}
                        barMinWidth={3600}
                        background={{
                            color1: logitarTheme.palette.background.default,
                            color2: pSBC(-0.2, logitarTheme.palette.background.default),
                            interval: 3600 * 12,
                            offset: timestampLocalToUTC(3600 * 21 * 1000) / 1000
                        }}
                        onDrag={onTimelineDrag}
                        headerFormatter={(e) => {
                            const date = new Date(e * 1000);
                            if (date.getHours() === 0 || date.getHours() === 12) {
                                return format(date, 'dd.MM HH');
                            }
                            return format(date, 'HH');
                        }}
                        onDrop={(e) => {
                            console.log(dragItemData, e);
                            if (e.point.row >= 0) {
                                const ndate = new Date(e.point.start * 1000);
                                const nshift = ndate.getHours() >= 12;

                                const newVehicles = [...vehicles];
                                // Insert dummy item to calculate slot
                                newVehicles[e.point.row].jobs.splice(e.point.column, 0, {
                                    item: {
                                        id: dragItemData.item,
                                        name: dragItem.current.el.innerText
                                    },
                                    
                                    date: format(ndate, "yyyy-MM-dd"),
                                    shift: convertShift(nshift),
                                    estStart: new Date(e.point.start * 1000),
                                    estDuration: 2,
                                    state: 0,
                                    slot: 0
                                });
                                updateSlots(newVehicles[e.point.row], format(ndate, "yyyy-MM-dd"), convertShift(nshift))
                                // Should not update cars - plans will be fetched again when new plan is sent
                                //setCars(newCars);

                                sendNewJob(dragItemData, {
                                    date: format(ndate, "yyyy-MM-dd"),
                                    shift: nshift,
                                    vehicle: newVehicles[e.point.row].vehicle.id,
                                    slot: newVehicles[e.point.row].jobs[e.point.column].slot
                                });
                                // Need to remove plan, duplicate otherwise. Does spread operator (newCars = [...cars]) not work? TODO: clean up
                                newVehicles[e.point.row].jobs.splice(e.point.column, 1);
                            }

                        }}
                        onContextMenu={(e) => {

                            if (vehicles[e.bar.row] && vehicles[e.bar.row].jobs[e.bar.column]) {
                                setCtxElement(e.event.target);
                                setCtxBar([e.bar.row, e.bar.column]);
                            }
                        }}
                        onDoubleClick={(e) => {
                            /*
                            if(e.point.row >= 0) {
                                const newTlData = [...tlData];
                                newTlData[e.point.row].data.splice(e.point.column, 0, {
                                    text: "nimike5",
                                    start: e.point.start,
                                    size: 3600
                                });
                                setTlData(newTlData);
                            }
                            */
                        }}
                        onHover={(e) => {
                            if (e.type === 'enter') {
                                if (vehicles[e.bar.row] && vehicles[e.bar.row].jobs[e.bar.column]) {
                                    const rect = e.event.target.getBoundingClientRect();
                                    setHoverPos([rect.left, rect.top]);
                                    setHoverText(vehicles[e.bar.row].jobs[e.bar.column].item.name);
                                }
                            }
                            else {
                                setHoverPos([-1, -1]);
                            }
                        }}
                    />
                }
            </Box>
        </Box>
        <Box sx={{ml: 1, display: 'flex', flexDirection: "column", flex: 1, overflow: "auto", maxHeight: "100%"}}>{/**column */}
            <Box
                sx={{ margin: '0 auto', mt: 1 }}
            >
                <ToggleButtonGroup
                    value={rightMenu}
                    onChange={(e, v) => {
                        if (v) {
                            setRightMenu(v);
                        }
                    }}
                    exclusive
                    sx={{
                        ".MuiToggleButton-root": {
                            padding: 1
                        }
                    }}
                >
                    <ToggleButton value={'orders'}>Tilaukset</ToggleButton>
                    <ToggleButton value={'clients'}>Asiakkaat</ToggleButton>

                </ToggleButtonGroup>
            </Box>
            {
                rightMenu === 'orders' &&
                <Box component={'fieldset'} sx={{
                    display: "flex", flexWrap: "wrap", border: 1,
                    borderColor: "#cccccc", p: 1, maxHeight: "100%", width: "auto", minWidth: "150px",
                    borderRadius: 1, mb: 1, overflow: "auto"
                }}>
                    <legend style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.75rem', fontWeight: 400 }}>Tilaukset</legend>
                    {(!loading) ? orders.map((value, index) => {
                        if ((!value.recurring && value.remaining < (1 / 7)) || value.ordersLeft == 0) {
                            return null;
                        }
                        return (
                            <DragBox
                                key={"dbo" + index}
                                id={value.item}
                                number={`${value.ordersLeft}`}
                                text={`${value.name}`}
                                //subtext={`${value.details}`}
                                tooltip={value.details}
                                dragStart={(e) => { setDragItemData({ ...{ type: 'order' }, ...value }); dragStart(e) }}
                                size={'100%'}
                                className={
                                    ((value.details != null && value.details != '') ? "default-green" : "") +
                                    (value.orderBacklogged ? "default-red-text" : "")
                                }
                            />
                        )
                    })
                        : <Loader />}
                </Box>
            }
            {
                rightMenu === 'clients' &&
                <Box sx={{ maxHeight: "100%" }}>
                    <Box sx={{ flexDirection: "column" }}>
                        <InputLabel sx={{ fontSize: 16 }}>Asiakaskoodi</InputLabel>
                        <Select
                            value={client}
                            onChange={clientSelectionChange}
                            sx={{ minWidth: 150, height: 50 }}>
                            <MenuItem value={false}>-</MenuItem>
                            {clientsList && clientsList.map((value, key) =>
                                <MenuItem value={value.id} key={key}>{value.name}</MenuItem>
                            )}
                        </Select>
                    </Box>

                    <Box sx={{ p: 1, maxWidth: 300, display: "flex", flexWrap: "wrap", overflow: "auto", maxHeight: "90%" }}>
                        {clientsItems &&
                            clientsItems.map((item, index) => (
                                <DragBox
                                    key={"dbc" + index}
                                    id={item.id}
                                    text={item.name}
                                    subtext={""}
                                    dragStart={(e) => { setDragItemData({ ...{ type: 'item' }, ...item }); dragStart(e) }}
                                />
                            ))}
                    </Box>
                </Box>
            }
        </Box>
    </Box>
}