
/**
 * @type {{name: string, code: number, description: string}[]}
 */
 export const LogitarError = [
    // GENERAL
    {
        name:           "ERR_NO_ERROR",
        code:           0x00,
        description:    "Ei virheitä"
    },
    {
        name:           "ERR_GENERIC",
        code:           0x01,
        description:    "Yleinen virhe"
    },
    // AUTH
    {
        name:           "ERR_AUTH_GENERIC",
        code:           0x10,
        description:    "Todennusvirhe"
    },
    {
        name:           "ERR_AUTH_INVALID",
        code:           0x11,
        description:    "Virheellinen API avain"
    },
    {
        name:           "ERR_AUTH_EXPIRED",
        code:           0x12,
        description:    "Vanhentunut API avain"
    },
    {
        name:           "ERR_AUTH_PERMISSION",
        code:           0x13,
        description:    "Riittämättömät oikeudet toimintoon"
    },
    {
        name:           "ERR_AUTH_LOGIN",
        code:           0x14,
        description:    "Väärä käyttäjätunnus/salasana"
    },
    // DATABASE
    {
        name:           "ERR_DB_GENERIC",
        code:           0x20,
        description:    "Tietokantavirhe"
    },
    {
        name:           "ERR_DB_CONNECT",
        code:           0x21,
        description:    "Tietokantaan yhdistäminen epäonnistui"
    },
    {
        name:           "ERR_DB_INSERT",
        code:           0x22,
        description:    "Rivin lisääminen/päivittäminen epäonnistui"
    },
    {
        name:           "ERR_DB_SELECT",
        code:           0x23,
        description:    "Rivien hakeminen epäonnistui"
    },
    {
        name:           "ERR_DB_DELETE",
        code:           0x24,
        description:    "Rivien poistaminen epäonnistui"
    },
    {
        name:           "ERR_DB_UPDATE",
        code:           0x25,
        description:    "Rivien päivittäminen epäonnistui"
    },
    {
        name:           "ERR_DB_NOT_FOUND",
        code:           0x26,
        description:    "Riviä ei löydetty"
    },
    // REQUEST
    {
        name:           "ERR_REQ_GENERIC",
        code:           0x40,
        description:    "Pyyntö epäonnistui"
    },
    {
        name:           "ERR_REQ_PARAM_NULL",
        code:           0x41,
        description:    "Puutteelliset parametrit kyselyssä"
    },
    {
        name:           "ERR_REQ_PARAM_FORMAT",
        code:           0x42,
        description:    "Virheelliset parametrit kyselyssä"
    },
    {
        name:           "ERR_REQ_JOB_EXISTS",
        code:           0x43,
        description:    "Kuorma on jo olemassa"
    },
    // CONFIG
    {
        name:           "ERR_CONFIG_NOT_FOUND",
        code:           0x60,
        description:    "Asetustiedosto puuttuu"
    },
    {
        name:           "ERR_CONFIG_MOD_DISABLED",
        code:           0x61,
        description:    "Moduuli on poistettu käytöstä"
    },
    {
        name:           "ERR_CONFIG_MOD_UNKNOWN",
        code:           0x62,
        description:    "Tuntematon moduuli"
    },
    // INTEGRATION
    {
        name:           "ERR_INTG_FUTURSOFT_LOGIN",
        code:           0x80,
        description:    "FuturSoft kirjautuminen epäonnistui"
    },
    {
        name:           "ERR_INTG_FUTURSOFT_POST",
        code:           0x81,
        description:    "FuturSoft POST virhe"
    },
    {
        name:           "ERR_INTG_FUTURSOFT_GET",
        code:           0x82,
        description:    "FuturSoft GET virhe"
    },
    {
        name:           "ERR_INTG_TALENOM_INVOICE",
        code:           0x86,
        description:    "Talenom POST virhe"
    }
]

/**
 * @brief Gets error object (name, code, description) with error code
 * @param {number} code 
 * @returns {{name: string, code: number, description: string}|undefined} error code or undefined if not found
 */
export const getError = (code) => {
    return LogitarError.find((e) => e.code === code);
}

/**
 * @brief Gets formatted log with error code, ex. "0x01 - ERR_GENERIC"
 * @param {number} code 
 * @returns {string} Formatted log
 */
 export const getErrorLog = (code) => {
    let err = LogitarError.find((e) => e.code === code);
    if(err) {
        return `0x${err.code.toString(16)} - ${err.name}`;
    }
    else {
        // Fallback 
        return "0x01 - ERR_GENERIC";
    }
}

/**
 * @brief Gets error description with error code
 * @param {number} code 
 * @returns {string} error string
 */
 export const getErrorDescription = (code) => {
    let err = LogitarError.find((e) => e.code === code);
    if(err) {
        return err.description;
    }
    else {
        // Fallback 
        return "Virhe";
    }
}